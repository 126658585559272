import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import EmploymentFieldsValueCounting from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/Counting';
import TextWrapper from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/TextWrapper';

interface WorkFormatsTextProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const TrlKeys = {
    label: 'vacancy.view.employment.workFormats.label',
};

const WorkFormatsText: TranslatedComponent<WorkFormatsTextProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
    trls,
}) => {
    const dictionary = useSelector((state) => state.vacancyEmploymentFieldsDictionary.workFormats);
    const workFormats = useSelector((state) => state.vacancyView.workFormats);

    if (workFormats.length === 0) {
        return null;
    }

    return (
        <TextWrapper isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile}>
            {`${trls[TrlKeys.label]}: `}

            <EmploymentFieldsValueCounting
                field="workFormats"
                dictionary={dictionary}
                values={workFormats}
                isMagritteVacancy={isMagritteVacancy}
            />
        </TextWrapper>
    );
};

export default translation(WorkFormatsText);
