import { FC, MutableRefObject } from 'react';

import VacancyContactsNotRedesigned from 'src/components/VacancyView/VacancyContacts/VacancyContacts';
import VacancyContactsRedesigned from 'src/components/VacancyView/VacancyContacts/VacancyContactsRedesigned';

interface VacancyContactsProps {
    vacancyBodyFooterNodeRef?: MutableRefObject<HTMLDivElement>;
    blockRef: MutableRefObject<HTMLDivElement>;
    hasVacancySectionWraper?: boolean;
    isRedesign: boolean;
    isMagritte?: boolean;
}

const VacancyContacts: FC<VacancyContactsProps> = ({
    isRedesign,
    vacancyBodyFooterNodeRef,
    blockRef,
    hasVacancySectionWraper,
    isMagritte,
}) => {
    if (isRedesign) {
        return (
            <VacancyContactsRedesigned
                blockRef={blockRef}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                hasVacancySectionWraper={hasVacancySectionWraper}
            />
        );
    }
    return (
        <VacancyContactsNotRedesigned
            blockRef={blockRef}
            hasVacancySectionWraper={hasVacancySectionWraper}
            isMagritte={isMagritte}
        />
    );
};

export default VacancyContacts;
