import { useLayoutEffect } from 'react';
import type { Primitive } from 'type-fest';

import urlParser from 'bloko/common/urlParser';

import { getPVRequestHeaders } from 'HHC/Banners';
import fetcher from 'src/utils/fetcher';

const PLACE = '00000';

declare global {
    interface FetcherPostApi {
        '/pv/fake': {
            body: void;
            response: void;
            queryParams: Record<string, Primitive | Primitive[]>;
        };
    }
}

const useAdvInfoRequest = (): void => {
    useLayoutEffect(() => {
        const bannersBatchUrl = window.globalVars?.bannersBatchUrl;

        if (bannersBatchUrl) {
            const url = urlParser(bannersBatchUrl);

            url.params.place = [PLACE];

            void fetcher.post(`${url.protocol}//${url.host}/pv/fake` as '/pv/fake', undefined, {
                withCredentials: true,
                params: url.params,
                headers: getPVRequestHeaders(),
            });
        }
    }, []);
};

export default useAdvInfoRequest;
