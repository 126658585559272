import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { PartTimeOption } from 'src/models/vacancyView.types';
import { arrayToHumanReadableList } from 'src/utils/humanReadableList';

const TrlKeys = {
    parttimeOptions: 'vacancy.view.parttime.options',
    or: 'or',
    options: {
        [PartTimeOption.Shifts]: 'vacancy.view.parttime.option.shifts',
        [PartTimeOption.Weekends]: 'vacancy.view.parttime.option.weekends',
        [PartTimeOption.Evenings]: 'vacancy.view.parttime.option.evenings',
    },
};

interface VacancyParttimeOptionsProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const VacancyParttimeOptions: TranslatedComponent<VacancyParttimeOptionsProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
    trls,
}) => {
    const parttimeOptions = useSelector((store) => store.vacancyView.parttimeOptions);

    const parttimeOptionsArray = Object.values(PartTimeOption).filter((parttimeKey) => {
        return parttimeOptions[parttimeKey];
    });

    if (!parttimeOptionsArray.length) {
        return null;
    }

    const parttimeOptionsArrayTrl = parttimeOptionsArray.map((parttimeKey) => {
        return trls[TrlKeys.options[parttimeKey]];
    });
    const parttimeOptionsTrl = arrayToHumanReadableList(parttimeOptionsArrayTrl, trls[TrlKeys.or]);

    const textContent = (
        <>
            {trls[TrlKeys.parttimeOptions]}
            {': '}
            {parttimeOptionsTrl}
        </>
    );

    if (isMagritteVacancy) {
        return (
            <Text
                Element="p"
                typography={isMobile ? 'label-3-regular' : 'label-2-regular'}
                data-qa="vacancy-view-parttime-options"
            >
                {textContent}
            </Text>
        );
    }

    return (
        <p
            className={classnames({
                'vacancy-description-list-item': isRedesign,
            })}
            data-qa="vacancy-view-parttime-options"
        >
            {textContent}
        </p>
    );
};

export default translation(VacancyParttimeOptions);
