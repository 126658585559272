import { FC } from 'react';
import htmlReactParser from 'html-react-parser';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import { useSelector } from 'src/hooks/useSelector';

import VacancySectionWrapper from 'src/components/VacancyView/VacancySectionWrapper';

interface VacancyDescriptionProps {
    hasVacancySectionWrapper?: boolean;
}

const VacancyDescription: FC<VacancyDescriptionProps> = ({ hasVacancySectionWrapper = true }) => {
    const description = useSelector(({ vacancyView }) => vacancyView.description);

    return (
        <VacancySectionWrapper hasWrapper={hasVacancySectionWrapper}>
            <div className="g-user-content" data-qa="vacancy-description">
                <AsyncHighlighter>{htmlReactParser(description)}</AsyncHighlighter>
            </div>
        </VacancySectionWrapper>
    );
};

export default VacancyDescription;
