import { PropsWithChildren, FC } from 'react';
import classnames from 'classnames';

import { useSelector } from 'src/hooks/useSelector';

interface VacancySectionWrapperProps {
    hasWrapper?: boolean;
}

const VacancySectionWrapper: FC<VacancySectionWrapperProps & PropsWithChildren> = ({ hasWrapper, children }) => {
    const isMagritteVacancy = useSelector((state) => state.isMagritteVacancy);

    if (hasWrapper) {
        const className = classnames('vacancy-section', {
            'vacancy-section_magritte': isMagritteVacancy,
        });
        return <div className={className}>{children}</div>;
    }

    return <>{children}</>;
};

export default VacancySectionWrapper;
