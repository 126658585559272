import { BrandingType } from 'src/models/vacancyView';

export interface BrandedPicture {
    path: string;
    blurredPath?: string;
    width?: number;
    height?: number;
}

export interface VacancyConstructorTemplate {
    name?: string;
    topPicture?: BrandedPicture;
    bottomPicture?: BrandedPicture;
}

export const CHAMELEON_COLUMNS = { m: '9', l: '9' };

export const URL_TYPES = {
    [BrandingType.Constructor]: 'vacancy-constructor',
    [BrandingType.Chameleon]: 'makeup',
};
