import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

type Properties = Array<{
    property: Array<{
        propertyType: string;
        parameters: Array<{
            parameter: Array<{
                key: string;
                value: string;
            }>;
        }>;
    }>;
}>;

function findZpCrossposting(properties: Properties) {
    return properties[0]?.property
        .find((property) => property.propertyType === 'ZP_CROSSPOSTING')
        ?.parameters[0].parameter.find((param) => param.key === 'zpPublicationTimeIso')?.value;
}

export function usePublicationDate(): string {
    const publicationDate = useSelector((state) => state.vacancyView.publicationDate || '');
    const properties = useSelector((state) => state.vacancyView.vacancyProperties?.properties);
    const isZP = useIsZarplataPlatform();

    if (!isZP || !properties) {
        return publicationDate;
    }

    try {
        const crossposting = findZpCrossposting(properties as unknown as Properties);
        return crossposting || publicationDate;
    } catch (_) {
        return publicationDate;
    }
}
