import { FC, MutableRefObject, PropsWithChildren, ReactNode } from 'react';

import { VacancyConstructorView, VacancyContainer } from '@hh.ru/branding-packages';

import { useSelector } from 'src/hooks/useSelector';

import Sidebar from 'src/components/VacancyView/VacancyConstructor/Sidebar';
import useMapWidgetToConfig, {
    firstSectionList,
    secondSectionList,
    WidgetName,
} from 'src/components/VacancyView/VacancyConstructor/external/useMapWidgetToConfig';
import useExternalWidgets from 'src/components/VacancyView/VacancyConstructor/external/useWidgetList';

interface VacancyConstructorProps {
    VacancyContainer: VacancyContainer;
    vacancyBodyFooterNodeRef: MutableRefObject<HTMLDivElement>;
    contactsNodeRef: MutableRefObject<HTMLDivElement>;
    resumeFormNodeRef: MutableRefObject<HTMLDivElement>;
    isRedesign?: boolean;
    isEmployerView: boolean;
    ContainerWrapper: FC<PropsWithChildren>;
    hideContent?: boolean;
    hasTopPadding?: boolean;
    renderSpoiler?: () => ReactNode;
    beforeSections: () => ReactNode;
}

const VacancyConstructor: FC<VacancyConstructorProps> = ({
    VacancyContainer,
    vacancyBodyFooterNodeRef,
    contactsNodeRef,
    resumeFormNodeRef,
    isRedesign,
    isEmployerView,
    hasTopPadding,
    ContainerWrapper,
    hideContent,
    renderSpoiler,
    beforeSections,
}) => {
    const templateData = useSelector(({ vacancyView }) => vacancyView.branding);
    const MapWidgetToConfig = useMapWidgetToConfig({
        isRedesign: !!isRedesign,
        vacancyBodyFooterNodeRef,
        contactsNodeRef,
        resumeFormNodeRef,
    });

    const { firstSectionBottomItems, secondSectionBottomItems, betweenSections, vacancyBody, top } = useExternalWidgets(
        {
            firstSectionList,
            secondSectionList,
            betweenSectionsWidget: WidgetName.BetweenSections,
            vacancyBodyWidget: WidgetName.VacancyBody,
            top: isEmployerView || !isRedesign ? WidgetName.EmployerViewTop : WidgetName.VacancyBlockTop,
            MapWidgetToConfig,
        }
    );

    if (!templateData) {
        return null;
    }

    return (
        <VacancyConstructorView
            templateData={templateData}
            VacancyContainer={VacancyContainer}
            isRedesign={isRedesign}
            topContent={top}
            SidebarComponent={() => <Sidebar vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />}
            hasTopPadding={hasTopPadding}
            betweenSections={betweenSections}
            ContainerWrapper={ContainerWrapper}
            firstSectionBottomItems={firstSectionBottomItems}
            secondSectionBottomItems={secondSectionBottomItems}
            vacancyBody={vacancyBody}
            hideContent={hideContent}
            renderSpoiler={renderSpoiler}
            beforeSections={beforeSections}
        />
    );
};

export default VacancyConstructor;
