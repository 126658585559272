import { FC, PropsWithChildren } from 'react';

import { FirstSectionFooterBlock } from '@hh.ru/branding-packages';

import { AppStore } from 'src/app/store';
import VacancyCreationTime, { checkVacancyCreationTimeRender } from 'src/components/VacancyView/VacancyCreationTime';
import { checkVacancyFooterRender } from 'src/components/VacancyView/VacancyFooter';
import VacancyMap, { checkVacancyMapRender } from 'src/components/VacancyView/VacancyMap';
import VacancySimilarResumes, {
    checkVacancySimilarResumesRender,
} from 'src/components/VacancyView/VacancySimilarResumes';

import { MapperElementConfig } from 'src/components/VacancyView/VacancyConstructor/external/externalWidgets.types';
import { useWidgetList } from 'src/components/VacancyView/VacancyConstructor/external/useWidgetList';

enum BlockName {
    VacancyMap = 'VacancyMap',
    VacancyCreationTime = 'VacancyCreationTime',
    VacancySimilarResumes = 'VacancySimilarResumes',
}

interface FirstSectionFooterProps {
    isRedesign?: boolean;
    hasWrapper?: boolean;
}

const getMapBlockToConfig = (isRedesign: boolean, hasWrapper: boolean): Record<string, MapperElementConfig> => ({
    [BlockName.VacancyMap]: {
        Element: <VacancyMap isRedesign={isRedesign} hasVacancySectionWrapper={hasWrapper} />,
        checkRender: checkVacancyMapRender,
    },
    [BlockName.VacancyCreationTime]: {
        Element: <VacancyCreationTime isRedesign={isRedesign} />,
        checkRender: (state: AppStore) => checkVacancyCreationTimeRender(state) && checkVacancyFooterRender(state),
    },
    [BlockName.VacancySimilarResumes]: {
        Element: <VacancySimilarResumes />,
        checkRender: checkVacancySimilarResumesRender,
    },
});

export const checkFirstSectionFooterRender = (state: AppStore): boolean =>
    checkVacancyMapRender(state) ||
    (checkVacancyCreationTimeRender(state) && checkVacancyFooterRender(state)) ||
    checkVacancySimilarResumesRender(state);

const FirstSectionFooter: FC<FirstSectionFooterProps & PropsWithChildren> = ({ isRedesign, hasWrapper }) => {
    const MapBlockToConfig = getMapBlockToConfig(!!isRedesign, !!hasWrapper);

    const Blocklist = useWidgetList(Object.values(BlockName), MapBlockToConfig);

    return (
        <div>
            {Blocklist.map(({ Element }, index) => (
                <FirstSectionFooterBlock key={index}>{Element}</FirstSectionFooterBlock>
            ))}
        </div>
    );
};

export default FirstSectionFooter;
