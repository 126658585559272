import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';

import styles from './styles.less';

interface EmploymentFieldsTextWrapperProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
    isDotted?: boolean;
}

const EmploymentFieldsTextWrapper: React.FC<
    EmploymentFieldsTextWrapperProps & { children: React.ReactNode | React.ReactNode[] }
> = ({ isRedesign, isMagritteVacancy, isMobile, isDotted, children }) => {
    const [, setWrapperWidth] = useState(0);

    const dottedWrapperRef = useRef<HTMLParagraphElement>(null);

    const dottedSpanRefs = useMemo(() => {
        if (!Array.isArray(children)) {
            if (children) {
                return [{ child: children, ref: createRef<HTMLSpanElement>() }];
            }

            return [];
        }

        return children.reduce<Array<{ child: React.ReactNode; ref: React.RefObject<HTMLSpanElement> }>>(
            (acc, child) => {
                if (child) {
                    acc.push({
                        child,
                        ref: createRef<HTMLSpanElement>(),
                    });
                }

                return acc;
            },
            []
        );
    }, [children]);

    const renderDottedSpansRefs = useCallback(() => {
        return dottedSpanRefs.map(({ child, ref }, index) => (
            <span
                ref={ref}
                key={index}
                className={classnames(styles.text, {
                    [styles.dot]: (ref?.current?.clientWidth || 0) > 0 && (ref?.current?.offsetLeft || 0) > 0,
                })}
            >
                {child}
            </span>
        ));
    }, [dottedSpanRefs]);

    useEffect(() => {
        if (dottedWrapperRef.current === null || typeof ResizeObserver === 'undefined') {
            return () => undefined;
        }

        const resizeObserver = new ResizeObserver(() => {
            dottedWrapperRef.current && setWrapperWidth(dottedWrapperRef.current.clientWidth);
        });

        resizeObserver.observe(dottedWrapperRef.current);

        return () => {
            resizeObserver.disconnect();
        };
    }, [dottedSpanRefs]);

    if (!isDotted) {
        if (isMagritteVacancy) {
            return (
                <Text Element="p" typography={isMobile ? 'label-3-regular' : 'label-2-regular'}>
                    {children}
                </Text>
            );
        }

        return (
            <p
                className={classnames({
                    'vacancy-description-list-item': isRedesign,
                })}
            >
                {children}
            </p>
        );
    }

    if (dottedSpanRefs.length === 0) {
        return null;
    }

    if (isMagritteVacancy) {
        return (
            <Text typography={isMobile ? 'label-3-regular' : 'label-2-regular'}>
                <div ref={dottedWrapperRef} className={styles.dottedWrapper}>
                    {renderDottedSpansRefs()}
                </div>
            </Text>
        );
    }

    return (
        <p
            ref={dottedWrapperRef}
            className={classnames(styles.dottedWrapper, {
                'vacancy-description-list-item': isRedesign,
            })}
        >
            {renderDottedSpansRefs()}
        </p>
    );
};

export default translation(EmploymentFieldsTextWrapper);
