import { FC, RefObject, MutableRefObject, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Card, VSpacing as MagritteVSpacing, VSpacingContainer, useBreakpoint } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import VacancyActions from 'src/components/VacancyView/VacancyActions';
import VacancyEmployment from 'src/components/VacancyView/VacancyEmployment';
import VacancyOnlineUsersCounter from 'src/components/VacancyView/VacancyOnlineUsersCounter';
import VacancyResponseQuestions from 'src/components/VacancyView/VacancyResponseQuestions';
import VacancyTitleRedesigned from 'src/components/VacancyView/VacancyTitleRedesigned';
import { useSelector } from 'src/hooks/useSelector';

import styles from './vacancy-block.less';

interface VacancyBlockTopWrapperProps extends PropsWithChildren {
    hasWrapper: boolean;
    isMagritteVacancy: boolean;
    isMobile: boolean;
}

const VacancyBlockTopWrapper: FC<VacancyBlockTopWrapperProps> = ({
    children,
    hasWrapper,
    isMagritteVacancy,
    isMobile,
}) => {
    if (!hasWrapper) {
        return <>{children}</>;
    }

    const content = isMagritteVacancy ? (
        <Card stretched padding={isMobile ? 16 : 24} borderRadius={24} showBorder>
            {children}
        </Card>
    ) : (
        <div className={styles.wrapperFlat}>{children}</div>
    );
    const spacing = isMagritteVacancy ? <MagritteVSpacing default={24} gteL={48} /> : <VSpacing base={9} xs={6} />;

    return (
        <ColumnsRow>
            <Column xs="4" s="8" m="12" l="10">
                {content}
            </Column>
            {spacing}
        </ColumnsRow>
    );
};

interface VacancyBlockTopProps {
    resumeFormNodeRef: RefObject<HTMLDivElement>;
    contactsNodeRef: RefObject<HTMLDivElement>;
    vacancyBodyFooterNodeRef: MutableRefObject<HTMLDivElement>;
    hasWrapper?: boolean;
    isChatQuestionExp?: boolean;
    hasQuestion?: boolean;
}

const VacancyBlockTop: FC<VacancyBlockTopProps> = ({
    resumeFormNodeRef,
    contactsNodeRef,
    vacancyBodyFooterNodeRef,
    hasWrapper = true,
    isChatQuestionExp,
    hasQuestion,
}) => {
    const isMagritteVacancy = useSelector((state) => state.isMagritteVacancy);
    const { isMobile } = useBreakpoint();
    const actions = (
        <div className="noprint">
            <VacancyActions
                contactsNodeRef={contactsNodeRef}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                resumeFormNodeRef={resumeFormNodeRef}
                isRedesign
                isMagritteVacancy={isMagritteVacancy}
                hasQuestion={hasQuestion}
            />
        </div>
    );
    const content = isMagritteVacancy ? (
        <VSpacingContainer default={isMobile ? 16 : 24}>
            <Card stretched padding={isMobile ? 8 : 0}>
                <VSpacingContainer default={12}>
                    <VacancyTitleRedesigned isMagritteVacancy isMobile={isMobile} />
                    <VSpacingContainer default={8}>
                        <VacancyEmployment isRedesign isMagritteVacancy isMobile={isMobile} />
                        <VacancyOnlineUsersCounter isRedesign isMagritteVacancy isMobile={isMobile} />
                    </VSpacingContainer>
                </VSpacingContainer>
            </Card>
            {actions}
        </VSpacingContainer>
    ) : (
        <>
            <VacancyTitleRedesigned />
            <VSpacing base={5} />
            <VacancyEmployment isRedesign />
            <VSpacing base={7} />
            <VacancyOnlineUsersCounter isRedesign />
            {actions}
        </>
    );

    return (
        <VacancyBlockTopWrapper hasWrapper={hasWrapper} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile}>
            {content}
            {isChatQuestionExp && !hasQuestion && (
                <div className={classnames(styles.question, 'noprint')}>
                    <VacancyResponseQuestions
                        type="question"
                        vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                        hasBottomGap={false}
                        hasSuggest={false}
                    />
                </div>
            )}
        </VacancyBlockTopWrapper>
    );
};

export default VacancyBlockTop;
