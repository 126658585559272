import { useSelector } from 'src/hooks/useSelector';

import VacancyNewEmploymentsFields from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields';
import VacancyOldEmploymentsFields from 'src/components/VacancyView/VacancyEmployment/VacancyOldEmploymentsFields';

interface VacancyEmploymentProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const VacancyEmployment: React.FC<VacancyEmploymentProps> = ({ isRedesign, isMagritteVacancy, isMobile }) => {
    const isVacancyViewNewEmploymentFields = useSelector(
        ({ vacancyNewEmploymentFieldsEnabled }) => vacancyNewEmploymentFieldsEnabled
    );

    if (isVacancyViewNewEmploymentFields) {
        return (
            <VacancyNewEmploymentsFields
                isRedesign={isRedesign}
                isMagritteVacancy={isMagritteVacancy}
                isMobile={isMobile}
            />
        );
    }

    return (
        <VacancyOldEmploymentsFields
            isRedesign={isRedesign}
            isMagritteVacancy={isMagritteVacancy}
            isMobile={isMobile}
        />
    );
};

export default VacancyEmployment;
