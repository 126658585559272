import { VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { AppStore } from 'src/app/store';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import VacancyAdditionalAccept from 'src/components/VacancyView/VacancyAdditionalAccept';
import VacancyHasTestText from 'src/components/VacancyView/VacancyHasTestText';
import VacancySectionWrapper from 'src/components/VacancyView/VacancySectionWrapper';
import useIsVisibleUserTest from 'src/components/VacancyView/hooks/useIsVisibleUserTest';

const TrlKeys = {
    acceptHandicapped: 'vacancy.view.acceptHandicapped',
    acceptHandicappedHint: 'acceptHandicapped.info',
    acceptKids: 'vacancy.view.acceptKids',
    acceptKidsHint: 'acceptKids.info',
};

export const checkVacancyAdditionalAcceptsRender = ({ vacancyView }: AppStore): boolean =>
    vacancyView['@acceptHandicapped'] || vacancyView['@acceptKids'];
interface VacancyAdditionalAcceptsProps {
    hasVacancySectionWrapper?: boolean;
    isEmployerView?: boolean;
}
const VacancyAdditionalAccepts: TranslatedComponent<VacancyAdditionalAcceptsProps> = ({
    hasVacancySectionWrapper = true,
    isEmployerView = false,
    trls,
}) => {
    const acceptHandicapped = useSelector(({ vacancyView }) => vacancyView['@acceptHandicapped']);
    const acceptKids = useSelector(({ vacancyView }) => vacancyView['@acceptKids']);
    const showAdditionalAccepts = useSelector(checkVacancyAdditionalAcceptsRender);
    const isVisibleUserTest = useIsVisibleUserTest();
    if (!showAdditionalAccepts && !isVisibleUserTest) {
        return null;
    }
    return (
        <VacancySectionWrapper hasWrapper={hasVacancySectionWrapper}>
            <VSpacingContainer default={10}>
                <VacancyHasTestText />
                {acceptHandicapped && (
                    <VacancyAdditionalAccept
                        icon="handicapped"
                        text={trls[TrlKeys.acceptHandicapped]}
                        hint={trls[TrlKeys.acceptHandicappedHint]}
                        isEmployerView={isEmployerView}
                    />
                )}
                {acceptKids && (
                    <VacancyAdditionalAccept
                        icon="kids"
                        text={trls[TrlKeys.acceptKids]}
                        hint={trls[TrlKeys.acceptKidsHint]}
                        isEmployerView={isEmployerView}
                    />
                )}
            </VSpacingContainer>
        </VacancySectionWrapper>
    );
};

export default translation(VacancyAdditionalAccepts);
