import PrintButton from 'src/components/VacancyActions/PrintButton';
import usePrintVacancy from 'src/components/VacancyCreate/usePrintVacancy';
import { useSelector } from 'src/hooks/useSelector';

const VacancyPreviewTools = () => {
    const vacancyPreviewTools = useSelector((state) => state.vacancyPreviewTools);
    const [printId, isDraft, show] = usePrintVacancy();

    if (!vacancyPreviewTools) {
        return null;
    }

    return (
        <div className="vacancy-preview-footer-tools">
            <div className="vacancy-section">{show && <PrintButton id={printId} draft={isDraft} />}</div>
        </div>
    );
};

export default VacancyPreviewTools;
