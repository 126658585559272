import { FC, RefObject } from 'react';
import classnames from 'classnames';

import { VSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';

import { AppStore } from 'src/app/store';
import { useSelector } from 'src/hooks/useSelector';
import { TopLevelSite } from 'src/models/topLevelSite';
import { UserType } from 'src/models/userType';

import VacancyResponse from 'src/components/VacancyView/VacancyResponse';

export const checkVacancyApplicantResponseRender = (state: AppStore): boolean => {
    if (![UserType.Applicant, UserType.NedoUser].includes(state.userType)) {
        return false;
    }

    const vacancyId = state.vacancyView?.vacancyId;
    const responseStatus = vacancyId ? state.applicantVacancyResponseStatuses?.[vacancyId] : undefined;
    const alreadyResponded = (responseStatus?.usedResumeIds?.length || 0) > 0;

    return !state.vacancyView.showResumeForm && !alreadyResponded;
};

interface VacancyApplicantResponseProps {
    vacancyBodyFooterNodeRef: RefObject<HTMLDivElement>;
    isRedesign: boolean;
}

const VacancyApplicantResponse: FC<VacancyApplicantResponseProps> = ({ vacancyBodyFooterNodeRef, isRedesign }) => {
    const showResponse = useSelector(checkVacancyApplicantResponseRender);
    const topLevelSite = useSelector((store) => store.topLevelSite);
    const isMagritteVacancy = useSelector((state) => state.isMagritteVacancy);

    if (!showResponse) {
        return null;
    }

    const showNewFooter = topLevelSite === TopLevelSite.RU || topLevelSite === TopLevelSite.BY;
    const actionsClassName = classnames('vacancy-actions', 'vacancy-actions_applicant', {
        'vacancy-actions_magritte-vacancy-bottom': isMagritteVacancy,
    });
    const actions = (
        <div className={actionsClassName}>
            <VacancyResponse
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                hideResponded={showNewFooter}
                isRedesign={isRedesign}
                isMagritteVacancy={isMagritteVacancy}
            />
        </div>
    );

    return (
        <div className="noprint">
            {isMagritteVacancy ? (
                <Column xs="4" s="8" m="12" l="10">
                    <VSpacing default={16} />
                    {actions}
                </Column>
            ) : (
                <Column
                    xs="4"
                    s={showNewFooter ? '7' : '8'}
                    m={showNewFooter ? '7' : '8'}
                    l={showNewFooter ? '7' : '10'}
                >
                    <Gap top>{actions}</Gap>
                </Column>
            )}
        </div>
    );
};

export default VacancyApplicantResponse;
