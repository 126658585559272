import { VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useShowAdvertisementAgreement from 'src/components/AdvertisementAgreement/useShowAdvertisementAgreement';
import useShowAdvertisementSubscriptionApplicant from 'src/components/AdvertisementSubscription/useShowAdvertisementSubscriptionApplicant';
import SignUpForm, {
    SignUpFormRenderProps,
    SignUpSignFormBaseProps,
} from 'src/components/ApplicantSignInSignUpFlow/SignUpStep/SignUpForm';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';

import Heading from 'src/components/VacancyView/SignUpBlock/Form/Heading';

import styles from './sign-up-step.less';

const TrlKeys = {
    header1: 'signUpStep.header.1',
    header2: 'signUpStep.header.2',
};

const SignUpStep: TranslatedComponent<SignUpSignFormBaseProps> = ({ trls, ...formProps }) => {
    const showAdvertisementAgreement = useShowAdvertisementAgreement();
    const showAdvertisementSubscriptionApplicant = useShowAdvertisementSubscriptionApplicant();
    const isMagritte = useMagritte();

    const render = ({
        renderFirstNameField,
        renderLastNameField,
        renderAccountDeleteInactive,
        renderSubmit,
        renderAdvertisementAgreement,
        renderAdvertisementSubscriptionApplicant,
    }: SignUpFormRenderProps) => {
        const firstNameInput = renderFirstNameField({
            size: isMagritte ? 'medium' : undefined,
        });
        const lastNameInput = renderLastNameField({
            size: isMagritte ? 'medium' : undefined,
        });

        return (
            <div className={isMagritte ? undefined : styles.wrapper}>
                <Heading>
                    {trls[TrlKeys.header1]} {trls[TrlKeys.header2]}
                </Heading>
                {!isMagritte && <VSpacing base={6} xs={4} />}
                {isMagritte ? (
                    <div className={styles.inputsWrapper}>
                        {firstNameInput}
                        {lastNameInput}
                    </div>
                ) : (
                    <>
                        {firstNameInput}
                        <VSpacing base={2} />
                        {lastNameInput}
                        <VSpacing base={4} />
                    </>
                )}
                {renderAccountDeleteInactive()}
                {showAdvertisementSubscriptionApplicant && (
                    <>
                        {renderAdvertisementSubscriptionApplicant()}
                        {isMagritte ? <MagritteVSpacing default={24} /> : <VSpacing base={6} />}
                    </>
                )}
                {renderSubmit()}
                {showAdvertisementAgreement && (
                    <div>
                        <Column l="5" m="5" s="5" xs="4" container>
                            {isMagritte ? <MagritteVSpacing default={20} /> : <VSpacing base={5} />}
                            {renderAdvertisementAgreement()}
                        </Column>
                    </div>
                )}
            </div>
        );
    };

    return <SignUpForm render={render} {...formProps} />;
};

export default translation(SignUpStep);
