import { FC, ReactNode, Ref, useRef, ComponentProps } from 'react';

import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    NavigationBar,
    Text,
    Tooltip,
    TooltipHover,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { ActionPropsType, TooltipType } from 'src/components/FieldTip/types';

interface TipWrapperProps {
    tipContent: ReactNode;
    bottomSheetTitle: string;
    useBottomSheet: boolean;
    visible: boolean;
    onClose: () => void;
    render: (props: { activatorRef?: Ref<HTMLElement> }) => ReactNode;
    tooltipType: TooltipType;
    actionProps?: ActionPropsType;
    action?: string;
    actionClose?: string;
    placement?: ComponentProps<typeof Tooltip>['placement'];
}

const TipWrapper: FC<TipWrapperProps> = ({
    tipContent,
    bottomSheetTitle,
    useBottomSheet,
    visible,
    onClose,
    render,
    tooltipType,
    action,
    actionProps = {},
    actionClose,
    placement = 'right-top',
}) => {
    const activatorRef = useRef<HTMLElement>(null);

    if (useBottomSheet) {
        return (
            <>
                {render({})}
                <BottomSheet
                    visible={visible}
                    onClose={onClose}
                    header={
                        <NavigationBar
                            title={bottomSheetTitle}
                            showDivider="always"
                            right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                        />
                    }
                    footer={
                        action && (
                            <BottomSheetFooter>
                                <Button
                                    {...actionProps}
                                    onClick={(event) => {
                                        actionProps.onClick?.(event);
                                        onClose();
                                    }}
                                    mode="secondary"
                                >
                                    {action}
                                </Button>
                            </BottomSheetFooter>
                        )
                    }
                >
                    <Text>{tipContent}</Text>
                </BottomSheet>
            </>
        );
    }

    if (tooltipType === TooltipType.Click) {
        const buttons =
            action || actionClose
                ? (onClose: VoidFunction) => (
                      <>
                          {!!action && (
                              <Button {...actionProps} style="contrast" mode="primary" size="small">
                                  {action}
                              </Button>
                          )}
                          {!!actionClose && (
                              <Button style="contrast" mode="secondary" size="small" onClick={onClose}>
                                  {actionClose}
                              </Button>
                          )}
                      </>
                  )
                : undefined;
        return (
            <>
                {render({ activatorRef })}
                <Tooltip
                    placement={placement}
                    activatorRef={activatorRef}
                    visible={visible}
                    onClose={onClose}
                    buttons={buttons}
                >
                    {tipContent}
                </Tooltip>
            </>
        );
    }

    return (
        <>
            {render({ activatorRef })}
            <TooltipHover placement={placement} activatorRef={activatorRef}>
                {tipContent}
            </TooltipHover>
        </>
    );
};
export default TipWrapper;
