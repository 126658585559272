import { ReactNode } from 'react';

import { ExternalWidgetConfig } from '@hh.ru/branding-packages';

import { AppStore } from 'src/app/store';
import { useSelector } from 'src/hooks/useSelector';

import { MapperElementConfig } from 'src/components/VacancyView/VacancyConstructor/external/externalWidgets.types';

const containsSameElements = (previous: unknown, next: unknown): boolean => {
    if (!Array.isArray(next) || !Array.isArray(previous)) {
        return false;
    }
    return previous.length === next.length && previous.every((element, index) => element === next[index]);
};

export const useWidgetList = (
    componentsCheckMap: string[],
    MapWidgetToConfig: Record<string, MapperElementConfig>
): ExternalWidgetConfig[] => {
    const widgetConfigs = componentsCheckMap
        .filter((item) => item in MapWidgetToConfig)
        .map((item) => MapWidgetToConfig[item]);
    const widgetConfigsToDisplay = useSelector(
        (state): MapperElementConfig[] => widgetConfigs.filter(({ checkRender }) => !checkRender || checkRender(state)),
        containsSameElements
    );
    return widgetConfigsToDisplay.map(({ checkRender, ...externalWidgetConfig }) => ({ ...externalWidgetConfig }));
};

const getElementByWidgetNameFunction =
    (widgetName: string, MapWidgetToConfig: Record<string, MapperElementConfig>) => (state: AppStore) => {
        if (widgetName in MapWidgetToConfig) {
            const config = MapWidgetToConfig[widgetName];
            const hasWidget = !config.checkRender || config.checkRender(state);
            return hasWidget ? config.Element : null;
        }
        console.error('Incorrect name of the external widget');
        return null;
    };

interface UseExternalWidgetsResult {
    firstSectionBottomItems: ExternalWidgetConfig[];
    secondSectionBottomItems: ExternalWidgetConfig[];
    betweenSections?: () => ReactNode;
    vacancyBody?: () => ReactNode;
    top?: () => ReactNode;
}

interface UseExternalWidgetsArg {
    firstSectionList: string[];
    secondSectionList: string[];
    betweenSectionsWidget: string;
    vacancyBodyWidget: string;
    MapWidgetToConfig: Record<string, MapperElementConfig>;
    top: string;
}

const useExternalWidget = (
    widgetName: string,
    MapWidgetToConfig: Record<string, MapperElementConfig>
): (() => ReactNode) | undefined => {
    const getElementByWidgetName = getElementByWidgetNameFunction(widgetName, MapWidgetToConfig);
    const content = useSelector(getElementByWidgetName);
    return content ? () => <>{content}</> : undefined;
};

export const useExternalWidgets = (arg: UseExternalWidgetsArg): UseExternalWidgetsResult => {
    const { firstSectionList, secondSectionList, betweenSectionsWidget, vacancyBodyWidget, MapWidgetToConfig, top } =
        arg;
    const betweenSections = useExternalWidget(betweenSectionsWidget, MapWidgetToConfig);
    const vacancyBody = useExternalWidget(vacancyBodyWidget, MapWidgetToConfig);
    const topContent = useExternalWidget(top, MapWidgetToConfig);
    const firstSectionBottomItems = useWidgetList(firstSectionList, MapWidgetToConfig);
    const secondSectionBottomItems = useWidgetList(secondSectionList, MapWidgetToConfig);
    return {
        firstSectionBottomItems,
        secondSectionBottomItems,
        betweenSections,
        vacancyBody,
        top: topContent,
    };
};

export default useExternalWidgets;
