import { FC, MouseEventHandler, useCallback, useMemo, PropsWithChildren } from 'react';

import employerButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/employer_button_click';
import { Text } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { H2Section } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import ItAccreditation from 'src/components/Employer/ItAccreditation';
import OrganizationForm from 'src/components/Employer/OrganizationForm';
import TrustedEmployerCheck, { TrustedEmployerCheckSize } from 'src/components/Employer/TrustedEmployerCheck';
import EmployerBadgesIconWrapper from 'src/components/EmployerBadges/EmployerBadgesIconWrapper';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import { useSelector } from 'src/hooks/useSelector';
import { Platform } from 'src/models/locale.types';
import { BillingPlatform } from 'src/models/price/product.types';
import { VacancyProperty } from 'src/models/vacancyProperties';

import usePreview from 'src/components/VacancyView/hooks/usePreview';

interface VacancyCompanyNameProps {
    constructorBrandedVacancy?: boolean;
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
}

const VacancyCompanyName: FC<VacancyCompanyNameProps> = ({
    constructorBrandedVacancy,
    isRedesign,
    isMagritteVacancy,
}) => {
    const company = useSelector((state) => state.vacancyView.company);
    const request = useSelector((state) => state.request);
    const isCrossposted = useSelector(
        (state) =>
            state.locale.platform === Platform.Zarplata &&
            ((state.vacancyView.platforms.includes(BillingPlatform.HH) &&
                state.vacancyView.platforms.includes(BillingPlatform.ZP)) ||
                state.vacancyView.vacancyProperties?.calculatedStates?.HH?.filteredPropertyNames.includes(
                    VacancyProperty.HhAuction
                ))
    );
    const isPreview = usePreview(request.url);
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);
    const companyNameOnClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (event) => {
            if (isPreview) {
                event.preventDefault();
                return;
            }
            if (vacancyId) {
                employerButtonClick({ vacancyId });
            }
        },
        [isPreview, vacancyId]
    );

    const { id, visibleName, employerOrganizationFormId, department } = company;

    const renderedCompanyName = useMemo(() => {
        const Heading = ({ children }: PropsWithChildren) => {
            return isMagritteVacancy ? (
                <Text Element="span" typography="title-5-semibold">
                    {children}
                </Text>
            ) : (
                <H2Section Element="span" lite>
                    {children}
                </H2Section>
            );
        };

        if (id) {
            if (isCrossposted) {
                return (
                    <Heading>
                        <OrganizationForm organizationFormId={employerOrganizationFormId} />
                        <AsyncHighlighter>{visibleName}</AsyncHighlighter>
                    </Heading>
                );
            }

            let companyHref = `/employer/${id}`;
            if (department) {
                companyHref += `?dpt=${department?.['@code']}`;
            }

            const linkContent = (
                <Heading>
                    <OrganizationForm organizationFormId={employerOrganizationFormId} />
                    <AsyncHighlighter>{visibleName}</AsyncHighlighter>
                </Heading>
            );

            return (
                <span className="vacancy-company-name">
                    {isMagritteVacancy ? (
                        <Link data-qa="vacancy-company-name" to={companyHref} onClick={companyNameOnClick}>
                            {linkContent}
                        </Link>
                    ) : (
                        <BlokoLink
                            Element={Link}
                            data-qa="vacancy-company-name"
                            to={companyHref}
                            onClick={companyNameOnClick}
                            kind={isRedesign ? LinkKind.Tertiary : undefined}
                        >
                            {linkContent}
                        </BlokoLink>
                    )}
                </span>
            );
        }

        return (
            <AsyncHighlighter>
                <span className="vacancy-company-name">
                    {isRedesign ? <Heading>{visibleName}</Heading> : visibleName}
                </span>
            </AsyncHighlighter>
        );
    }, [
        id,
        visibleName,
        isCrossposted,
        department,
        companyNameOnClick,
        isRedesign,
        isMagritteVacancy,
        employerOrganizationFormId,
    ]);

    return (
        <div data-qa="vacancy-company__details" className="vacancy-company-details">
            {renderedCompanyName}
            <EmployerBadgesIconWrapper>
                {company['@trusted'] && <TrustedEmployerCheck size={TrustedEmployerCheckSize.Small} />}
                {!isMagritteVacancy && company.accreditedITEmployer && (
                    <ItAccreditation isInverted={!isRedesign && constructorBrandedVacancy} />
                )}
            </EmployerBadgesIconWrapper>
        </div>
    );
};

export default VacancyCompanyName;
