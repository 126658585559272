import { RefObject, useCallback } from 'react';

import Button from 'bloko/blocks/button';
import Modal, { ModalFooter, ModalContent } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyActionResponse, { VacancyActionResponseKind } from 'src/components/VacancyView/VacancyActionResponse';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import ReportVideo from 'src/components/VacancyView/VacancyVideo/ReportVideo';

import styles from './vacancy-video.less';

const TrlKeys = {
    close: 'applicant.videoVacancy.modal.close',
    respond: 'applicant.videoVacancy.modal.respond',
};

interface VacancyVideoModalProps {
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
    src?: string;
    visible: boolean;
    onClose: () => void;
    onOpen: () => void;
    onPlaying?: () => void;
    onPlay?: () => void;
    onPause?: () => void;
    onTimeUpdate?: (time: number) => void;
    onVolumeChange?: (volume: number) => void;
    onDurationLoaded?: (duration: number) => void;
    onRespondClick?: () => void;
}

const VacancyVideoModal: TranslatedComponent<VacancyVideoModalProps> = ({
    visible,
    src,
    onClose,
    onOpen,
    onPlaying,
    onPlay,
    onPause,
    onTimeUpdate,
    onVolumeChange,
    onDurationLoaded,
    onRespondClick,
    vacancyBodyFooterNodeRef,
    trls,
}) => {
    const { archived } = useSelector((state) => state.vacancyView.status);

    const videoRef = useCallback(
        (node: HTMLVideoElement | null) =>
            node?.addEventListener('loadeddata', () => onDurationLoaded?.(node.duration)),
        [onDurationLoaded]
    );

    return (
        <Modal visible={visible} onClose={onClose}>
            <ModalContent>
                <video
                    ref={videoRef}
                    autoPlay
                    className={styles.videoPlayer}
                    src={src}
                    controls
                    controlsList="nofullscreen nodownload"
                    preload="metadata"
                    onPlaying={onPlaying}
                    onPlay={onPlay}
                    onPause={onPause}
                    onTimeUpdate={(event) => onTimeUpdate?.((event.target as HTMLMediaElement).currentTime)}
                    onVolumeChange={(event) => onVolumeChange?.((event.target as HTMLMediaElement).volume)}
                />
            </ModalContent>
            <ModalFooter>
                <div className={styles.modalBottom}>
                    <ReportVideo onClose={onClose} />
                    <span className={styles.spacer} />
                    <span className={styles.close}>
                        <Button onClick={onClose}>{trls[TrlKeys.close]}</Button>
                    </span>
                    {!archived ? (
                        <VacancyActionResponse
                            kind={VacancyActionResponseKind.VideoVacancy}
                            vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                            onResponseComplete={onClose}
                            onScrollToSignupForm={onClose}
                            onPopupCancelCallback={onOpen}
                            onRespondClick={onRespondClick}
                        />
                    ) : null}
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default translation(VacancyVideoModal);
