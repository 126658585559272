import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import EmploymentFieldsValueCounting from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/Counting';
import TextWrapper from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/TextWrapper';
import { isOtherValue } from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/utils';

interface WorkingHoursTextProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const TrlKeys = {
    label: 'vacancy.view.employment.workingHours.label',
    nightShifts: 'vacancy.view.employment.workingHours.nightShifts',
};

const WorkingHoursText: TranslatedComponent<WorkingHoursTextProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
    trls,
}) => {
    const dictionary = useSelector((state) => state.vacancyEmploymentFieldsDictionary.workingHours);
    const workingHours = useSelector((state) => state.vacancyView.workingHours);
    const nightShifts = useSelector((state) => state.vacancyView.nightShifts);

    const isEmpty = workingHours.length === 0 || (workingHours.length === 1 && isOtherValue(workingHours[0]));

    if (isEmpty && !nightShifts) {
        return null;
    }

    if (isEmpty && nightShifts) {
        return (
            <TextWrapper isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile}>
                {trls[TrlKeys.nightShifts]}
            </TextWrapper>
        );
    }

    return (
        <TextWrapper isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile} isDotted>
            <>
                {`${trls[TrlKeys.label]}: `}

                <EmploymentFieldsValueCounting
                    field="workingHours"
                    dictionary={dictionary}
                    values={workingHours}
                    isMagritteVacancy={isMagritteVacancy}
                />
            </>

            {nightShifts && trls[TrlKeys.nightShifts]}
        </TextWrapper>
    );
};

export default translation(WorkingHoursText);
