import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EmploymentType } from 'src/models/vacancyView.types';
import { ScheduleCamelCase } from 'src/utils/constants/schedule';

const TrlKeys = {
    employment: {
        [EmploymentType.Probation]: 'vacancy.employment.probation',
        [EmploymentType.Full]: 'vacancy.employment.full',
        [EmploymentType.Part]: 'vacancy.employment.part',
        [EmploymentType.Volunteer]: 'vacancy.employment.volunteer',
        [EmploymentType.Project]: 'vacancy.employment.project',
    },
    workSchedule: {
        [ScheduleCamelCase.Shift]: 'vacancy.workSchedule.shift',
        [ScheduleCamelCase.FullDay]: 'vacancy.workSchedule.fullDay',
        [ScheduleCamelCase.FlyInFlyOut]: 'vacancy.workSchedule.flyInFlyOut',
        [ScheduleCamelCase.Flexible]: 'vacancy.workSchedule.flexible',
        [ScheduleCamelCase.Remote]: 'vacancy.workSchedule.remote',
    },
};

interface VacancyEmploymentProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const VacancyEmployment: TranslatedComponent<VacancyEmploymentProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
    trls,
}) => {
    const employment = useSelector((state) => state.vacancyView.employment);
    const workSchedule = useSelector((state) => state.vacancyView['@workSchedule']);
    const type = employment?.['@type'];
    const textContent = (
        <>
            {type && (
                <>
                    {trls[TrlKeys.employment[type]]}
                    {', '}
                </>
            )}
            {workSchedule && <span>{trls[TrlKeys.workSchedule[workSchedule]]}</span>}
        </>
    );

    if (isMagritteVacancy) {
        return (
            <Text
                Element="p"
                typography={isMobile ? 'label-3-regular' : 'label-2-regular'}
                data-qa="vacancy-view-employment-mode"
            >
                {textContent}
            </Text>
        );
    }

    return (
        <p
            className={classnames({
                'vacancy-description-list-item': isRedesign,
            })}
            data-qa="vacancy-view-employment-mode"
        >
            {textContent}
        </p>
    );
};

export default translation(VacancyEmployment);
