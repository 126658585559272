import { FC } from 'react';

import { Skeleton } from '@hh.ru/magritte-ui';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

const VacancyActionBlacklistComplain: FC = () => {
    const userType = useSelector((state) => state.userType);
    const vacancyView = useSelector((state) => state.vacancyView);
    const canComplain = vacancyView.features.includes('canComplain');

    if (!canComplain || userType !== UserType.Applicant) {
        return null;
    }

    return (
        <div className="vacancy-action vacancy-action_complain">
            <ContainerForMicroFrontend
                place="employerComplain"
                preloadContent={<Skeleton height={48} width={149} loading borderRadius={4} />}
            />
        </div>
    );
};
export default VacancyActionBlacklistComplain;
