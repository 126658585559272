import { RefObject, ReactNode } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';
import Button, { ButtonType, ButtonKind } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import VacancyActionComplain from 'src/components/VacancyView/VacancyActionComplain';
import VacancyActionResponse, { VacancyActionResponseKind } from 'src/components/VacancyView/VacancyActionResponse';

import styles from './VacancyResponse.less';

const TrlKeys = {
    response: 'button.placeResponse',
};

interface VacancyResponseProps {
    hideResponded?: boolean;
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
}

const VacancyResponse: TranslatedComponent<VacancyResponseProps> = ({
    hideResponded = false,
    trls,
    isRedesign,
    isMagritteVacancy,
    vacancyBodyFooterNodeRef,
}) => {
    const showSignupForm = useSelector((state) => state.vacancyView.showSignupForm);
    const status = useSelector((state) => state.vacancyView.status);
    const isBrandingPreview = useSelector((state) => state.vacancyView.isBrandingPreview);
    const isTrusted = useSelector((state) => state.vacancyView.company['@trusted']);

    if (showSignupForm || status.archived || status.disabled) {
        return null;
    }

    let renderCustomWrapper: undefined | ((content: ReactNode) => JSX.Element);
    if (isMagritteVacancy) {
        renderCustomWrapper = (content: ReactNode) => {
            return <div className={styles.responseButtonWrapper}>{content}</div>;
        };
    }

    return isBrandingPreview && !isRedesign ? (
        <Button type={ButtonType.Button} kind={ButtonKind.Success} stretched>
            {trls[TrlKeys.response]}
        </Button>
    ) : (
        <>
            <VacancyActionResponse
                isSecondary
                hideResponded={hideResponded}
                isRedesign={isRedesign}
                isMagritteVacancy={isMagritteVacancy}
                kind={VacancyActionResponseKind.AfterBody}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                renderCustomWrapper={renderCustomWrapper}
            />
            {!isTrusted && <VacancyActionComplain />}
            {isMagritteVacancy && <VSpacing default={24} gteS={0} />}
        </>
    );
};

export default translation(VacancyResponse);
