import { FC } from 'react';

import { HSpacingContainer } from '@hh.ru/magritte-ui';
import VSpacing from 'bloko/blocks/vSpacing';

import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';
import { useSelector } from 'src/hooks/useSelector';

import EmployerCards from 'src/components/VacancyView/EmployerCards';
import VacancyCompanyAddress from 'src/components/VacancyView/VacancyCompanyAddress';
import VacancyCompanyLogo from 'src/components/VacancyView/VacancyCompanyLogo';
import VacancyCompanyName from 'src/components/VacancyView/VacancyCompanyName';

type Props = {
    isMagritteVacancy?: boolean;
};

const VacancyCompanyRedesigned: FC<Props> = ({ isMagritteVacancy = false }) => {
    const companyId = useSelector((state) => state.vacancyView.company.id);
    const badges = useSelector((state) => state.vacancyView.company.badges);
    const trusted = useSelector((state) => state.vacancyView.company['@trusted']);
    const name = <VacancyCompanyName isRedesign isMagritteVacancy={isMagritteVacancy} />;
    const logo = <VacancyCompanyLogo isRedesign isMagritteVacancy={isMagritteVacancy} />;
    const widget = trusted ? (
        <div className="noprint">
            <EmployerReviewsWidgetSmall isRedesign isMagritteVacancy={isMagritteVacancy} />
        </div>
    ) : null;
    const address = isMagritteVacancy ? null : <VacancyCompanyAddress isRedesign />;
    const content = isMagritteVacancy ? (
        <HSpacingContainer default={16}>
            {logo}
            <div>
                {name}
                {widget}
            </div>
        </HSpacingContainer>
    ) : (
        <>
            {logo}
            {name}
            {widget}
            <VSpacing base={3} />
        </>
    );

    return (
        <div className="vacancy-company-redesigned" data-qa="vacancy-company">
            {content}
            <EmployerCards companyId={companyId} badges={badges} isMagritteVacancy={isMagritteVacancy} />
            {address}
        </div>
    );
};

export default VacancyCompanyRedesigned;
