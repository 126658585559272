import VacancyAcceptTemporary from 'src/components/VacancyView/VacancyEmployment/VacancyOldEmploymentsFields/VacancyAcceptTemporary';
import VacancyEmployment from 'src/components/VacancyView/VacancyEmployment/VacancyOldEmploymentsFields/VacancyEmployment';
import VacancyParttimeOptions from 'src/components/VacancyView/VacancyEmployment/VacancyOldEmploymentsFields/VacancyParttimeOptions';
import VacancyWorkExperience from 'src/components/VacancyView/VacancyEmployment/VacancyOldEmploymentsFields/VacancyWorkExperience';

interface VacancyOldEmploymentsFieldsProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const VacancyOldEmploymentsFields: React.FC<VacancyOldEmploymentsFieldsProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
}) => {
    return (
        <>
            <VacancyWorkExperience isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile} />
            <VacancyEmployment isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile} />
            <VacancyAcceptTemporary isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile} />
            <VacancyParttimeOptions isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile} />
        </>
    );
};

export default VacancyOldEmploymentsFields;
