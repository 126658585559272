import { VacancyConstructorTemplate } from 'src/components/VacancyView/brandingConstants';
import { useSelector } from 'src/hooks/useSelector';
import { BrandingType } from 'src/models/vacancyView';

interface BrandingData {
    isBranded: boolean;
    isConstructor: boolean;
    isChameleon: boolean;
    isBrandingPreview: boolean;
    brandingType: BrandingType | null;
    constructorTemplate: VacancyConstructorTemplate | null;
}

const useBranding = (): BrandingData => {
    const isBrandingPreview = useSelector(({ vacancyView }) => vacancyView.isBrandingPreview);
    const brandingType = useSelector(({ vacancyView }) => vacancyView.brandingType);
    const constructorTemplate = useSelector(({ vacancyView }) => vacancyView.vacancyConstructorTemplate);

    return {
        isBranded: !!brandingType,
        isConstructor: brandingType === BrandingType.Constructor,
        isChameleon: brandingType === BrandingType.Chameleon,
        isBrandingPreview: !!isBrandingPreview,
        brandingType: brandingType || null,
        constructorTemplate: constructorTemplate || null,
    };
};

export default useBranding;
