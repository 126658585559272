import { FC } from 'react';

import Column from 'bloko/blocks/column';

import { AppStore } from 'src/app/store';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import VacancyResponse from 'src/components/VacancyView/VacancyResponse';

export const checkVacancyAnonymousResponseRender = (state: AppStore): boolean => {
    return [UserType.Anonymous, UserType.NedoUser].includes(state.userType);
};

export interface VacancyAnonymousResponseProps {
    isRedesign: boolean;
}

const VacancyAnonymousResponse: FC<VacancyAnonymousResponseProps> = ({ isRedesign }) => {
    const showResponse = useSelector(checkVacancyAnonymousResponseRender);

    if (!showResponse) {
        return null;
    }

    return (
        <div className="noprint">
            <Column xs="4" s="8" m={isRedesign ? '12' : '8'} l={isRedesign ? '10' : '8'}>
                <div className="vacancy-actions vacancy-actions_applicant">
                    <div className="vacancy-action vacancy-action_after-body">
                        <VacancyResponse isRedesign={isRedesign} />
                    </div>
                </div>
            </Column>
        </div>
    );
};

export default VacancyAnonymousResponse;
