import { FormItem } from 'bloko/blocks/form';
import { H2 } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format, formatToReactComponent } from 'bloko/common/trl';

import MultiAccountGroupingForm, {
    MultiAccountGroupingFormBaseProps,
} from 'src/components/ApplicantSignInSignUpFlow/MultiAccountGroupingStep/MultiAccountGroupingForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './multi-account-grouping.less';

const TrlKeys = {
    passwordSent: 'registration.registered.passwordSent',
    letterComeSoon: 'registration.registered.letterComeSoon',
    letterMultiaccountNote: 'registration.registered.letterMultiaccountNote',
    letterHighlightedPart: 'registration.registered.letterHighlightedPart',
};

const MultiAccountGroupingStep: TranslatedComponent<MultiAccountGroupingFormBaseProps> = ({ trls, ...formProps }) => {
    const { login } = useSelector(({ otp }) => otp);

    return (
        <MultiAccountGroupingForm
            render={({ renderInput, renderError, renderSubmit }) => {
                return (
                    <div className={styles.wrapper}>
                        <H2>
                            {format(trls[TrlKeys.passwordSent], {
                                '{0}': login as string,
                            })}
                        </H2>
                        <VSpacing base={3} />
                        <p>
                            {formatToReactComponent(trls[TrlKeys.letterMultiaccountNote], {
                                '{0}': (
                                    <Text Element="span" strong>
                                        {trls[TrlKeys.letterHighlightedPart]}
                                    </Text>
                                ),
                            })}
                        </p>
                        <p>{trls[TrlKeys.letterComeSoon]}</p>
                        <VSpacing base={6} xs={4} />
                        <FormItem>{renderInput()}</FormItem>
                        {renderError()}
                        <VSpacing base={4} />
                        {renderSubmit()}
                    </div>
                );
            }}
            {...formProps}
        />
    );
};

export default translation(MultiAccountGroupingStep);
