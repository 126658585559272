import { useContext } from 'react';

import vacancyVideoComplainAttemptButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/brandy_snippet/video_in_vacancy/vacancy_video_complain_attempt_button_click';
import vacancyVideoComplainButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/brandy_snippet/video_in_vacancy/vacancy_video_complain_button_click';
import Link, { LinkKind } from 'bloko/blocks/link';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import NotificationContext from 'bloko/blocks/notificationManager/context';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import styles from './vacancy-video.less';

const TrlKeys = {
    confirm: 'applicant.videoVacancy.modal.confirm',
    report: 'applicant.videoVacancy.modal.report',
    notification: 'applicant.videoVacancy.modal.notification',
};

interface ReportVideoProps {
    onClose: () => void;
}

const ReportVideo: TranslatedComponent<ReportVideoProps> = ({ trls, onClose }) => {
    const vacancyId = useSelector((state) => `${state.vacancyView.vacancyId || ''}`);
    const { addNotification } = useContext(NotificationContext);

    const userType = useSelector(({ userType }) => userType);
    const anonymousUserType = useSelector((state) => state.anonymousUserType);
    const isApplicant =
        userType === UserType.Applicant ||
        (userType === UserType.Anonymous && anonymousUserType === UserType.Applicant);

    if (!isApplicant) {
        return null;
    }

    return (
        <span className={styles.reportVideo}>
            <Link
                kind={LinkKind.Warning}
                onClick={() => {
                    vacancyVideoComplainAttemptButtonClick({ vacancyId });
                    // eslint-disable-next-line no-alert
                    if (confirm(trls[TrlKeys.confirm])) {
                        vacancyVideoComplainButtonClick({ vacancyId });
                        onClose();
                        addNotification({
                            kind: NotificationKind.Ok,
                            children: <>{trls[TrlKeys.notification]}</>,
                            autoClose: true,
                            autoCloseDelay: 5000,
                        });
                    }
                }}
            >
                {trls[TrlKeys.report]}
            </Link>
        </span>
    );
};

export default translation(ReportVideo);
