import { FC, RefObject } from 'react';

import { ColumnsRow } from 'bloko/blocks/column';

import { AppStore } from 'src/app/store';
import VacancyAnonymousResponse, {
    checkVacancyAnonymousResponseRender,
} from 'src/components/VacancyView/VacancyAnonymousResponse';
import VacancyApplicantResponse, {
    checkVacancyApplicantResponseRender,
} from 'src/components/VacancyView/VacancyApplicantResponse';

export const checkBetweenSectionsRender = (state: AppStore): boolean =>
    checkVacancyAnonymousResponseRender(state) || checkVacancyApplicantResponseRender(state);

export interface BetweenSectionsProps {
    vacancyBodyFooterNodeRef: RefObject<HTMLDivElement>;
    isRedesign: boolean;
}

const BetweenSections: FC<BetweenSectionsProps> = ({ vacancyBodyFooterNodeRef, isRedesign }) => {
    return (
        <ColumnsRow>
            <VacancyAnonymousResponse isRedesign={isRedesign} />
            <VacancyApplicantResponse vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} isRedesign={isRedesign} />
        </ColumnsRow>
    );
};

export default BetweenSections;
