import { FC, PropsWithChildren, ReactNode, Ref } from 'react';
import classnames from 'classnames';

import { Action, useBreakpoint } from '@hh.ru/magritte-ui';
import { QuestionCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import useOnOffState from 'src/hooks/useOnOffState';

import TipWrapper from 'src/components/FieldTip/TipWrapper';
import { ActionPropsType, TooltipType } from 'src/components/FieldTip/types';

import styles from './field-tip.less';

interface FieldTipProps extends PropsWithChildren {
    tipContent: ReactNode;
    fieldName: string;
    tooltipType?: TooltipType;
    action?: string;
    actionProps?: ActionPropsType;
    actionClose?: string;
    withoutVertPadding?: boolean;
    stretched?: boolean;
}

const FieldTip: FC<FieldTipProps> = ({
    tipContent,
    fieldName,
    children,
    action,
    actionProps,
    actionClose,
    tooltipType = TooltipType.Hover,
    withoutVertPadding = false,
    stretched = false,
}) => {
    const [visible, show, hide] = useOnOffState(false);
    const { isMobile: useBottomSheet } = useBreakpoint();

    return (
        <div className={styles.fieldTipWrapper}>
            <div className={classnames(styles.fieldTip, { [styles.fieldTipStretched]: stretched })}>
                {children}
                <TipWrapper
                    tipContent={tipContent}
                    bottomSheetTitle={fieldName}
                    useBottomSheet={useBottomSheet}
                    visible={visible}
                    onClose={hide}
                    tooltipType={tooltipType}
                    action={action}
                    actionProps={actionProps}
                    actionClose={actionClose}
                    render={({ activatorRef }) => (
                        <div
                            className={classnames({ [styles.fieldTipActionWithoutVertPadding]: withoutVertPadding })}
                            ref={activatorRef as Ref<HTMLDivElement>}
                        >
                            <Action
                                icon={QuestionCircleOutlinedSize16}
                                onClick={() => {
                                    if (useBottomSheet || tooltipType === TooltipType.Click) {
                                        show();
                                    }
                                }}
                                mode="secondary"
                                disablePadding={withoutVertPadding}
                            />
                        </div>
                    )}
                />
            </div>
        </div>
    );
};

export default FieldTip;
