import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import EmploymentFieldsValueCounting from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/Counting';
import TextWrapper from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/TextWrapper';
import { isOtherValue } from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/utils';

const TrlKeys = {
    label: 'vacancy.view.employment.workScheduleByDays.label',
};

interface WorkScheduleByDaysTextProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const WorkScheduleByDaysText: TranslatedComponent<WorkScheduleByDaysTextProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
    trls,
}) => {
    const dictionary = useSelector((state) => state.vacancyEmploymentFieldsDictionary.workScheduleByDays);
    const workScheduleByDays = useSelector((state) => state.vacancyView.workScheduleByDays);

    if (workScheduleByDays.length === 0 || (workScheduleByDays.length === 1 && isOtherValue(workScheduleByDays[0]))) {
        return null;
    }

    return (
        <TextWrapper isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile}>
            {`${trls[TrlKeys.label]}: `}

            <EmploymentFieldsValueCounting
                field="workScheduleByDays"
                dictionary={dictionary}
                values={workScheduleByDays}
                isMagritteVacancy={isMagritteVacancy}
            />
        </TextWrapper>
    );
};

export default translation(WorkScheduleByDaysText);
