import { MouseEvent } from 'react';
import classnames from 'classnames';

import { Button, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyResponseLink from 'src/components/VacancyResponseLink';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    buttonText: 'button.placeOtherResponse.short',
};

const VacancyActionResponseAgain: TranslatedComponent = ({ trls }) => {
    const { vacancyId } = useSelector((state) => state.vacancyView);
    const { employerId } = useSelector((state) => state.vacancyView);
    const responseStatuses = useSelector((state) => state.applicantVacancyResponseStatuses);
    const { isMobile } = useBreakpoint();

    if (!vacancyId) {
        return null;
    }

    const responseStatus = responseStatuses[vacancyId];
    const hasUsedResumes = Boolean(responseStatus?.usedResumeIds?.length);
    const hasUnusedResumes = Boolean(responseStatus?.unusedResumeIds?.length);
    const hasHiddenResumes = Boolean(responseStatus?.hiddenResumeIds?.length);
    const showButton = hasUnusedResumes || hasHiddenResumes;

    if (!hasUsedResumes || !showButton) {
        return null;
    }

    const renderButton = (onClick: (event?: MouseEvent<Element>) => void) => (
        <Button
            mode="secondary"
            style="accent"
            size={isMobile ? 'small' : 'medium'}
            stretched
            onClick={onClick}
            data-qa="vacancy-response-link-top-again"
        >
            {trls[TrlKeys.buttonText]}
        </Button>
    );

    const actionClassName = classnames(
        'vacancy-action',
        'vacancy-action_full-size',
        'vacancy-action_full-size-redesigned',
        'vacancy-action_stretched',
        'vacancy-action_stretched-redesigned'
    );

    return (
        <div className={actionClassName}>
            <VacancyResponseLink
                vacancyId={vacancyId}
                employerId={employerId}
                render={renderButton}
                place="vacancy_top_button"
            />
        </div>
    );
};

export default translation(VacancyActionResponseAgain);
