import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    acceptTemporary: 'vacancy.view.acceptTemporary',
};

interface VacancyAcceptTemporaryProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const VacancyAcceptTemporary: TranslatedComponent<VacancyAcceptTemporaryProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
    trls,
}) => {
    const acceptTemporary = useSelector((store) => store.vacancyView.acceptTemporary);
    if (!acceptTemporary) {
        return null;
    }

    const textContent = trls[TrlKeys.acceptTemporary];

    if (isMagritteVacancy) {
        return (
            <Text
                Element="p"
                typography={isMobile ? 'label-3-regular' : 'label-2-regular'}
                data-qa="vacancy-view-accept-temporary"
            >
                {textContent}
            </Text>
        );
    }

    return (
        <p
            className={classnames({ 'vacancy-description-list-item': isRedesign })}
            data-qa="vacancy-view-accept-temporary"
        >
            {textContent}
        </p>
    );
};

export default translation(VacancyAcceptTemporary);
