import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ExperienceOption } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    workExperience: 'vacancy.view.employment.workExperience',
    experience: {
        [ExperienceOption.NoExperience]: 'workExperience.noExperience',
        [ExperienceOption.Between1And3]: 'workExperience.between1And3',
        [ExperienceOption.Between3And6]: 'workExperience.between3And6',
        [ExperienceOption.MoreThan6]: 'workExperience.moreThan6',
    },
};

interface WorkExperienceProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const WorkExperience: TranslatedComponent<WorkExperienceProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
    trls,
}) => {
    const workExperience = useSelector((state) => state.vacancyView.workExperience);

    if (!workExperience) {
        return null;
    }

    const textContent = (
        <>
            {trls[TrlKeys.workExperience]}
            {': '}
            <span data-qa="vacancy-experience">{trls[TrlKeys.experience[workExperience]]}</span>
        </>
    );

    if (isMagritteVacancy) {
        return (
            <Text Element="p" typography={isMobile ? 'label-3-regular' : 'label-2-regular'}>
                {textContent}
            </Text>
        );
    }

    return (
        <p
            className={classnames({
                'vacancy-description-list-item': isRedesign,
            })}
        >
            {textContent}
        </p>
    );
};

export default translation(WorkExperience);
