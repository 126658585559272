import { useCallback, MutableRefObject, useRef } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import {
    Button as MagritteButton,
    Card as MagritteCard,
    Text as MagritteText,
    Title as MagritteTitle,
    VSpacingContainer as MagritteVSpacingContainer,
    useBreakpoint as useMagritteBreakpoint,
} from '@hh.ru/magritte-ui';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import { H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NoIndex from 'src/components/NoIndex';
import VacancyContactsDrop from 'src/components/VacancyView/VacancyContactsDrop';
import VacancySectionWrapper from 'src/components/VacancyView/VacancySectionWrapper';
import useContactsAnalytics from 'src/components/VacancyView/hooks/useContactsAnalytics';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { vacancySetVisibilityContactsAction } from 'src/models/vacancyView';

import checkRender from 'src/components/VacancyView/VacancyContacts/checkRender';

const TrlKeys = {
    contacts: 'vacancy.contactInfo.opened',
    showContactsButton: 'vacancy.action.showContacts',
    title: 'vacancy.contactInfo.title',
    contactPerson: 'vacancy.contactInfo.contactPerson',
};

interface VacancyContactsRedesignedProps {
    vacancyBodyFooterNodeRef?: MutableRefObject<HTMLDivElement>;
    blockRef: MutableRefObject<HTMLDivElement>;
    hasVacancySectionWraper?: boolean;
}
const VacancyContactsRedesigned: TranslatedComponent<VacancyContactsRedesignedProps> = ({
    vacancyBodyFooterNodeRef,
    blockRef,
    hasVacancySectionWraper = true,
    trls,
}) => {
    const dispatch = useDispatch();
    const userType = useSelector((state) => state.userType);
    const isAnonymous = userType === UserType.Anonymous;
    const isMagritteVacancy = useSelector((state) => state.isMagritteVacancy);
    const breakpoint = useBreakpoint();
    const { isMobile } = useMagritteBreakpoint();
    const show = useSelector((state) => checkRender(state, breakpoint));
    const sendViewContactsAnalytics = useContactsAnalytics({ place: 'vacancy_bottom_button' });
    const activatorRef = useRef(null);
    const {
        isVisibleContacts,
        vacancyId,
        company: { id: employerId },
        contactInfo,
    } = useSelector((state) => state.vacancyView);

    const handleOpen = useCallback(() => {
        dispatch(vacancySetVisibilityContactsAction(true));
        sendViewContactsAnalytics({ vacancyId, employerId, userType });
    }, [dispatch, employerId, sendViewContactsAnalytics, userType, vacancyId]);

    if (!show) {
        return null;
    }

    const renderMagritteContacts = () => {
        const fio = contactInfo?.fio ?? '';
        const isFioShown = Boolean(fio) && !isAnonymous;

        const button = (
            <VacancyContactsDrop
                show={isVisibleContacts}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                place="bottom"
                activatorRef={activatorRef}
            >
                <MagritteButton
                    style="accent"
                    mode="secondary"
                    onClick={handleOpen}
                    stretched={isMobile}
                    data-qa="show-employer-contacts show-employer-contacts_bottom-button"
                    ref={activatorRef}
                >
                    {trls[TrlKeys.showContactsButton]}
                </MagritteButton>
            </VacancyContactsDrop>
        );

        if (isMobile) {
            return (
                <MagritteCard stretched showBorder padding={12} borderRadius={24}>
                    <MagritteCard stretched padding={12} paddingBottom={24}>
                        {isFioShown ? (
                            <MagritteVSpacingContainer default={6}>
                                <MagritteText Element="p" typography="label-1-regular">
                                    {fio}
                                </MagritteText>
                                <MagritteText Element="p" typography="label-3-regular" style="secondary">
                                    {trls[TrlKeys.contactPerson]}
                                </MagritteText>
                            </MagritteVSpacingContainer>
                        ) : (
                            <MagritteText Element="p" typography="label-1-regular">
                                {trls[TrlKeys.title]}
                            </MagritteText>
                        )}
                    </MagritteCard>
                    {button}
                </MagritteCard>
            );
        }

        return (
            <MagritteVSpacingContainer default={16}>
                <MagritteTitle Element="h2" size="extra-small">
                    {trls[TrlKeys.title]}
                </MagritteTitle>
                {isFioShown && (
                    <MagritteText Element="p" typography="label-2-regular">
                        {fio}
                    </MagritteText>
                )}
                <div>{button}</div>
            </MagritteVSpacingContainer>
        );
    };

    const renderBlokoContacts = () => (
        <>
            <H2>{trls[TrlKeys.contacts]}</H2>
            <VSpacing base={6} />
            <VacancyContactsDrop
                show={isVisibleContacts}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                place="bottom"
                activatorRef={activatorRef}
            >
                <Button
                    scale={ButtonScale.Large}
                    appearance={ButtonAppearance.Outlined}
                    kind={ButtonKind.Primary}
                    onClick={handleOpen}
                    data-qa="show-employer-contacts show-employer-contacts_bottom-button"
                    innerRef={activatorRef}
                >
                    {trls[TrlKeys.showContactsButton]}
                </Button>
            </VacancyContactsDrop>
            <VSpacing base={6} />
        </>
    );

    return (
        <NoIndex>
            <div
                ref={blockRef}
                className={classnames('vacancy-contacts', {
                    noprint: !isVisibleContacts,
                })}
            >
                <VacancySectionWrapper hasWrapper={hasVacancySectionWraper}>
                    {isMagritteVacancy ? renderMagritteContacts() : renderBlokoContacts()}
                </VacancySectionWrapper>
            </div>
        </NoIndex>
    );
};

export default translation(VacancyContactsRedesigned);
