import { FC } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import videoVacancyCardElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy/video_vacancy_card_element_shown';
import Text from 'bloko/blocks/text';

import { useSelector } from 'src/hooks/useSelector';

import styles from './fake-player.less';

const FakePlayer: FC<{ onClick: () => void }> = ({ onClick }) => {
    const imageUrl = useSelector((state) => state.vacancyView.videoVacancyImageUrl) || '';
    const vacancyId = useSelector((state) => state.vacancyView.vacancyId);

    return (
        <ElementShownAnchor fn={videoVacancyCardElementShown} vacancyId={vacancyId || 0}>
            <div className={styles.root} style={{ backgroundImage: `url('${imageUrl}')` }} onClick={onClick}>
                <div className={styles.inner}>
                    <div className={styles.timeline}>
                        <div className={styles.play} />
                        <span className={styles.timelineItem}>
                            <Text>0:00</Text>
                        </span>
                        <svg className={styles.progress}>
                            <circle className={styles.progressItem} cx="3.5" cy="3.5" r="3.5" />
                            <rect
                                className={styles.progressItem}
                                opacity="0.5"
                                width="100%"
                                y="2.75"
                                height="2"
                                rx="1"
                                ry="1"
                            />
                        </svg>
                        <span className={styles.timelineItem}>
                            <Text>-:--</Text>
                        </span>
                    </div>
                </div>
            </div>
        </ElementShownAnchor>
    );
};

export default FakePlayer;
