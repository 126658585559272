import { PropsWithChildren, FC } from 'react';

import { VacancyBodyBlock } from '@hh.ru/branding-packages';

import VacancyAdditionalAccepts, {
    checkVacancyAdditionalAcceptsRender,
} from 'src/components/VacancyView/VacancyAdditionalAccepts';
import VacancyDescription from 'src/components/VacancyView/VacancyDescription';
import VacancyDriverLicense, { checkVacancyDriverLicenseRender } from 'src/components/VacancyView/VacancyDriverLicense';
import VacancyKeySkills, { checkVacancyKeySkillsRender } from 'src/components/VacancyView/VacancyKeySkills';

import { MapperElementConfig } from 'src/components/VacancyView/VacancyConstructor/external/externalWidgets.types';
import { useWidgetList } from 'src/components/VacancyView/VacancyConstructor/external/useWidgetList';

enum BlockName {
    VacancyDescription = 'VacancyDescription',
    VacancyAdditionalAccepts = 'VacancyAdditionalAccepts',
    VacancyKeySkills = 'VacancyKeySkills',
    VacancyDriverLicense = 'VacancyDriverLicense',
    VacancySkillSurvey = 'VacancySkillSurvey',
}

interface VacancyBodyProps {
    isRedesign?: boolean;
    hasWrapper?: boolean;
}

const getMapBlockToConfig = (isRedesign: boolean, hasWrapper: boolean): Record<string, MapperElementConfig> => ({
    [BlockName.VacancyDescription]: {
        Element: <VacancyDescription hasVacancySectionWrapper={hasWrapper} />,
    },
    [BlockName.VacancyAdditionalAccepts]: {
        Element: <VacancyAdditionalAccepts hasVacancySectionWrapper={hasWrapper} />,
        checkRender: checkVacancyAdditionalAcceptsRender,
    },
    [BlockName.VacancyKeySkills]: {
        Element: <VacancyKeySkills hasVacancySectionWrapper={hasWrapper} />,
        checkRender: checkVacancyKeySkillsRender,
    },
    [BlockName.VacancyDriverLicense]: {
        Element: <VacancyDriverLicense hasVacancySectionWrapper={hasWrapper} />,
        checkRender: checkVacancyDriverLicenseRender,
    },
});

const VacancyBody: FC<VacancyBodyProps & PropsWithChildren> = ({ isRedesign, hasWrapper }) => {
    const MapBlockToConfig = getMapBlockToConfig(!!isRedesign, !!hasWrapper);

    const Blocklist = useWidgetList(Object.values(BlockName), MapBlockToConfig);

    return (
        <div>
            {Blocklist.map(({ Element }, index) => (
                <VacancyBodyBlock key={index}>{Element}</VacancyBodyBlock>
            ))}
        </div>
    );
};

export default VacancyBody;
