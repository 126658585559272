import classnames from 'classnames';

import { Title, Text as MagritteText, VSpacingContainer } from '@hh.ru/magritte-ui';
import { H1Section } from 'bloko/blocks/header';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import HumanDate, { ShowYear } from 'src/components/HumanDate';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import VacancyCompensation from 'src/components/VacancyView/VacancyCompensation';

const TrlKeys = {
    archivedFrom: 'vacancy.view.archived.from',
};

type Props = {
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
};

const VacancyTitleRedesigned: TranslatedComponent<Props> = ({ trls, isMagritteVacancy, isMobile }) => {
    const { status, name, validThroughTime } = useSelector((state) => state.vacancyView);
    const titleContent = <AsyncHighlighter>{name}</AsyncHighlighter>;
    const isArchivedOrDisabled = status.archived || status.disabled;
    const isArchivedTextShown = status.archived && validThroughTime;
    const archivedText = isArchivedTextShown ? (
        <>
            {trls[TrlKeys.archivedFrom]}
            {NON_BREAKING_SPACE}
            <HumanDate date={validThroughTime} showYear={ShowYear.Always} />
        </>
    ) : null;

    if (isMagritteVacancy) {
        return (
            <VSpacingContainer default={12}>
                <div>
                    <Title
                        Element="h1"
                        style={isArchivedOrDisabled ? 'tertiary' : 'primary'}
                        size={isMobile ? 'medium' : 'extra-large'}
                        data-qa="vacancy-title"
                    >
                        {titleContent}
                        {archivedText && (
                            <MagritteText
                                data-qa="vacancy-title-archived-text"
                                typography="subtitle-3-semibold"
                                style="tertiary"
                            >
                                {archivedText}
                            </MagritteText>
                        )}
                    </Title>
                </div>
                <VacancyCompensation isRedesign isMagritteVacancy isMobile={isMobile} />
            </VSpacingContainer>
        );
    }

    return (
        <div
            className={classnames('vacancy-title', {
                'vacancy-title_archive': isArchivedOrDisabled,
            })}
        >
            <H1Section data-qa="vacancy-title">
                {titleContent}
                {archivedText && (
                    <Text data-qa="vacancy-title-archived-text" strong size={TextSize.Small}>
                        {archivedText}
                    </Text>
                )}
            </H1Section>
            <VSpacing base={4} />
            <VacancyCompensation isRedesign />
        </div>
    );
};

export default translation(VacancyTitleRedesigned);
