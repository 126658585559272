import classnames from 'classnames';

import { Button as MagritteButton, useBreakpoint } from '@hh.ru/magritte-ui';
import {
    EyeCrossedOutlinedSize16,
    EyeOutlinedSize16,
    EyeCrossedOutlinedSize24,
    EyeOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import Button, { ButtonAppearance } from 'bloko/blocks/button';
import { EyeScaleSmallCrossedFalse, EyeScaleSmallCrossedTrue, IconColor } from 'bloko/blocks/icon';
import IconDynamic from 'bloko/blocks/icon/IconDynamic';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import usePropertiesBundle from 'src/hooks/usePropertiesBundle';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import BlacklistMenu, { getTitle } from 'src/components/VacancyView/BlacklistMenu';
import { BlacklistStatus } from 'src/components/VacancyView/useBlacklistMenu';

const TrlKeys = {
    blacklistAdd: 'pageControls.BlacklistAdd',
    removeVacancy: 'pageControls.BlacklistRemoveVacancy',
    removeEmployer: 'pageControls.BlacklistRemoveEmployer',
};

interface VacancyActionBlacklistButtonProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
}

const VacancyActionBlacklistButton: TranslatedComponent<VacancyActionBlacklistButtonProps> = ({
    isRedesign,
    isMagritteVacancy,
    trls,
}) => {
    const userType = useSelector((state) => state.userType);
    const vacancyView = useSelector((state) => state.vacancyView);
    const propertiesBundle = usePropertiesBundle();
    const vacancyId = vacancyView.vacancyId;
    const employerId = vacancyView.company?.id;
    const isBrandingPreview = useSelector((state) => state.vacancyView.isBrandingPreview);
    const { isMobile } = useBreakpoint();

    if (userType !== UserType.Applicant || isBrandingPreview) {
        return null;
    }

    const isAnonymousVacancy = vacancyView.vacancyProperties.calculatedStates?.[propertiesBundle].anonymous || false;
    const getDataQa = (status: BlacklistStatus, isLoading: boolean) => {
        if (status === BlacklistStatus.Empty && !isLoading) {
            return 'vacancy__blacklist-show-add';
        }
        if (status !== BlacklistStatus.Empty && !isLoading) {
            return 'vacancy__blacklist-show-remove';
        }
        return undefined;
    };
    const renderIcon = (isBlacklisted: boolean) => {
        return !isBlacklisted ? (
            <EyeScaleSmallCrossedTrue initial={IconColor.Gray50} />
        ) : (
            <EyeScaleSmallCrossedFalse initial={IconColor.Gray50} />
        );
    };
    const getCommonProps = ({
        status,
        isLoading,
        onClick,
    }: {
        status: BlacklistStatus;
        isLoading: boolean;
        onClick: () => void;
    }) => {
        return {
            title: getTitle(status, {
                blacklistAdd: trls[TrlKeys.blacklistAdd],
                removeVacancy: trls[TrlKeys.removeVacancy],
                removeEmployer: trls[TrlKeys.removeEmployer],
            }),
            'data-qa': getDataQa(status, isLoading),
            onClick,
            disabled: isLoading,
        };
    };

    return (
        <span className="vacancy-action">
            <BlacklistMenu
                vacancyId={vacancyId}
                employerId={employerId}
                isAnonymousVacancy={isAnonymousVacancy}
                checkInitialStatus
                render={({ status, isLoading, onClick, activatorRef }) => {
                    const isBlacklisted = [
                        BlacklistStatus.BlacklistVacancy,
                        BlacklistStatus.BlacklistEmployer,
                    ].includes(status);
                    const commonProps = getCommonProps({
                        status,
                        isLoading,
                        onClick,
                    });

                    if (isMagritteVacancy) {
                        let magritteIcon: JSX.Element;
                        if (isBlacklisted) {
                            magritteIcon = isMobile ? <EyeOutlinedSize16 /> : <EyeOutlinedSize24 />;
                        } else {
                            magritteIcon = isMobile ? <EyeCrossedOutlinedSize16 /> : <EyeCrossedOutlinedSize24 />;
                        }
                        return (
                            <MagritteButton
                                ref={activatorRef}
                                style="accent"
                                mode="secondary"
                                size={isMobile ? 'small' : 'medium'}
                                hideLabel
                                aria-label={commonProps.title}
                                icon={magritteIcon}
                                {...commonProps}
                            />
                        );
                    }

                    return isRedesign ? (
                        <button
                            ref={activatorRef}
                            className="vacancy-action-blacklist-redesign vacancy-action-blacklist-redesign_appearance-outlined"
                            {...commonProps}
                        >
                            <span
                                className={classnames('vacancy-action-blacklist-icon-redesign', {
                                    'vacancy-action-blacklist-icon-redesign_checked': isBlacklisted,
                                })}
                            />
                        </button>
                    ) : (
                        <IconDynamic>
                            <Button
                                ref={activatorRef}
                                appearance={ButtonAppearance.Outlined}
                                icon={renderIcon(isBlacklisted)}
                                {...commonProps}
                            />
                        </IconDynamic>
                    );
                }}
            />
        </span>
    );
};

export default translation(VacancyActionBlacklistButton);
