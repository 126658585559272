import CommonEmploymentText from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/CommonEmploymentText';
import WorkExperience from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/WorkExperience';
import WorkFormatsText from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/WorkFormatsText';
import WorkScheduleByDaysText from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/WorkScheduleByDaysText';
import WorkingHoursText from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/WorkingHoursText';

interface VacancyNewEmploymentsFieldsProps {
    isRedesign?: boolean;
    isMagritteVacancy?: boolean;
    isMobile?: boolean;
}

const VacancyNewEmploymentsFields: React.FC<VacancyNewEmploymentsFieldsProps> = ({
    isRedesign,
    isMagritteVacancy,
    isMobile,
}) => {
    return (
        <>
            <WorkExperience isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile} />
            <CommonEmploymentText isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile} />
            <WorkScheduleByDaysText isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile} />
            <WorkingHoursText isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile} />
            <WorkFormatsText isRedesign={isRedesign} isMagritteVacancy={isMagritteVacancy} isMobile={isMobile} />
        </>
    );
};

export default VacancyNewEmploymentsFields;
