import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { AppStore } from 'src/app/store';
import HumanDate from 'src/components/HumanDate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import { usePublicationDate } from 'src/components/VacancyView/hooks/usePublicationDate';

const TrlKeys = {
    creationTime: 'vacancy.view.creationTimeAndRegion',
    vacancyCode: 'vacancy.header.vacancyCode',
};

export const checkVacancyCreationTimeRender = (state: AppStore): boolean =>
    !!state.vacancyView.publicationDate || !!state.vacancyView['@vacancyCode'];

interface VacancyCreationTimeProps {
    isRedesign?: boolean;
}

const VacancyCreationTime: TranslatedComponent<VacancyCreationTimeProps> = ({ isRedesign, trls }) => {
    const publicationDate = usePublicationDate();
    const area = useSelector((state) => state.vacancyView.area);
    const vacancyCode = useSelector((state) => state.vacancyView['@vacancyCode']);

    return (
        <>
            {publicationDate && (
                <p className={isRedesign ? 'vacancy-creation-time-redesigned' : 'vacancy-creation-time'}>
                    {formatToReactComponent(trls[TrlKeys.creationTime], {
                        '{0}': <HumanDate date={publicationDate} />,
                        '{1}': area?.areaNamePre || '',
                    })}
                </p>
            )}
            {vacancyCode && (
                <p className={isRedesign ? 'vacancy-code-redesigned' : 'vacancy-code'}>
                    {trls[TrlKeys.vacancyCode]}
                    &nbsp;
                    {vacancyCode}
                </p>
            )}
        </>
    );
};

export default translation(VacancyCreationTime);
