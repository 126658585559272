import { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { AppStore } from 'src/app/store';
import { UserType } from 'src/models/userType';

const isXs = (breakpoint?: Breakpoint): boolean => breakpoint === Breakpoint.XS;

const isUserAnonymous = (state: AppStore): boolean => state.userType === UserType.Anonymous;

const hasValidContactInfo = (state: AppStore): boolean =>
    !!state.vacancyView.contactInfo && state.vacancyView.vacancyId !== undefined;

const isDifferentEmployer = (state: AppStore): boolean =>
    state.userType === UserType.Employer && Number(state.employerId) !== state.vacancyView.company.id;

const checkRender = (state: AppStore, breakpoint?: Breakpoint): boolean => {
    return hasValidContactInfo(state) && (!isXs(breakpoint) || !isUserAnonymous(state)) && !isDifferentEmployer(state);
};

export default checkRender;
