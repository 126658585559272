import { PropsWithChildren, FC, RefObject } from 'react';

import VacancyActions from 'src/components/VacancyView/VacancyActions';
import VacancyCompany from 'src/components/VacancyView/VacancyCompany';
import VacancyEmployment from 'src/components/VacancyView/VacancyEmployment';
import VacancyOnlineUsersCounter from 'src/components/VacancyView/VacancyOnlineUsersCounter';
import VacancyTitle from 'src/components/VacancyView/VacancyTitle';

interface EmployerViewTopProps {
    resumeFormNodeRef: RefObject<HTMLDivElement>;
    contactsNodeRef: RefObject<HTMLDivElement>;
    vacancyBodyFooterNodeRef: RefObject<HTMLDivElement>;
    hasWrapper?: boolean;
}

const EmployerViewTop: FC<EmployerViewTopProps & PropsWithChildren> = ({
    contactsNodeRef,
    vacancyBodyFooterNodeRef,
    resumeFormNodeRef,
    hasWrapper,
}) => {
    return (
        <>
            <VacancyTitle hasWrapper={hasWrapper} />
            <VacancyCompany hasWrapper={hasWrapper} />
            <VacancyOnlineUsersCounter />
            <VacancyActions
                contactsNodeRef={contactsNodeRef}
                vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef}
                resumeFormNodeRef={resumeFormNodeRef}
                hasWrapper={hasWrapper}
            />
            <VacancyEmployment />
        </>
    );
};

export default EmployerViewTop;
