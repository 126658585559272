import { Fragment, useCallback, useEffect, MutableRefObject, FC, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Text } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { H2 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import fetchResponseType from 'src/api/contacts/fetchResponseType';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import NoIndex from 'src/components/NoIndex';
import ContactsCallTracking from 'src/components/VacancyView/VacancyContactsCallTracking';
import VacancyContactsLinkSwitch from 'src/components/VacancyView/VacancyContactsLinkSwitch';
import VacancySectionWrapper from 'src/components/VacancyView/VacancySectionWrapper';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import { vacancyChatInfoSetFetching, vacancyChatInfoUpdate } from 'src/models/vacancyChatInfo';

import checkRender from 'src/components/VacancyView/VacancyContacts/checkRender';

const TrlKeys = {
    contacts: 'vacancy.contactInfo.opened',
};

interface VacancyContactsProps {
    blockRef: MutableRefObject<HTMLDivElement>;
    hasVacancySectionWraper?: boolean;
    isMagritte?: boolean;
}

interface VacancyContactsTitleProps {
    children: ReactNode;
    isMagritte?: boolean;
}

const VacancyContactsTitle: FC<VacancyContactsTitleProps> = ({ children, isMagritte }) =>
    isMagritte ? <Text typography="subtitle-1-semibold">{children}</Text> : <H2>{children}</H2>;

const VacancyContacts: TranslatedComponent<VacancyContactsProps> = ({
    blockRef,
    hasVacancySectionWraper = true,
    trls,
    isMagritte,
}) => {
    const dispatch = useDispatch();
    const breakpoint = useBreakpoint();
    const contactInfo = useSelector(({ vacancyView }) => vacancyView.contactInfo);
    const show = useSelector((state) => checkRender(state, breakpoint));
    const vacancyId = useSelector(({ vacancyView }) => vacancyView.vacancyId);
    const isVisible = useSelector(({ vacancyView }) => vacancyView.isVisibleContacts);
    const isAnonymous = useSelector(({ userType }) => userType === UserType.Anonymous);
    const allowToShowContacts = isVisible && !isAnonymous;
    const chatButtonInfo = useSelector((state) => (vacancyId ? state.vacancyChatInfo[vacancyId] : null));
    const isZarplata = useIsZarplataPlatform();

    useEffect(() => {
        vacancyId && dispatch(vacancyChatInfoSetFetching({ vacancyId, isFetching: true }));

        if (!isAnonymous && vacancyId !== undefined) {
            void fetchResponseType(vacancyId).then((chatInfo) =>
                dispatch(vacancyChatInfoUpdate({ data: chatInfo, vacancyId }))
            );
        } else {
            vacancyId && dispatch(vacancyChatInfoUpdate({ data: null, vacancyId }));
        }
    }, [dispatch, isAnonymous, vacancyId]);

    const sendEmailOnClickAnalytics = useCallback(() => {
        Analytics.sendHHEvent('applicantEmailIntent', {
            from: 'vacancy',
            vacancy_id: String(vacancyId), // eslint-disable-line camelcase
        });
    }, [vacancyId]);

    const sendPhoneOnClickAnalytics = useCallback(() => {
        Analytics.sendHHEvent('applicantCallIntent', {
            from: 'vacancy',
            vacancy_id: String(vacancyId), // eslint-disable-line camelcase
        });
        Analytics.sendEvent('applicant', 'call-from-vacancy');
        if (isZarplata) {
            Analytics.addEventPixels('ZP_CALL');
        }
    }, [isZarplata, vacancyId]);

    const renderPhoneComment = (comment: string | undefined) => {
        if (!comment) {
            return null;
        }
        return (
            <>
                {', '}
                <AsyncHighlighter>{comment}</AsyncHighlighter>
            </>
        );
    };
    if (!show || chatButtonInfo?.isFetching || typeof vacancyId !== 'number') {
        return null;
    }

    const phones = contactInfo?.phones?.phones;

    return (
        <NoIndex>
            <div
                ref={blockRef}
                className={classnames('vacancy-contacts', {
                    'vacancy-contacts_opened': allowToShowContacts,
                })}
            >
                <VacancySectionWrapper hasWrapper={hasVacancySectionWraper}>
                    <VacancyContactsTitle isMagritte={isMagritte}>
                        {allowToShowContacts ? (
                            trls[TrlKeys.contacts]
                        ) : (
                            <VacancyContactsLinkSwitch isMagritte={isMagritte} />
                        )}
                    </VacancyContactsTitle>
                    {!isMagritte && <VSpacing base={4} />}
                    {allowToShowContacts &&
                        (contactInfo?.callTrackingEnabled && phones ? (
                            <ContactsCallTracking
                                contactInfo={contactInfo}
                                vacancyId={vacancyId}
                                onPhoneClick={sendPhoneOnClickAnalytics}
                                onEmailClick={sendEmailOnClickAnalytics}
                                chatButtonInfo={chatButtonInfo?.data}
                                block
                            />
                        ) : (
                            <div className="vacancy-contacts__body">
                                {contactInfo?.fio && (
                                    <p data-qa="vacancy-contacts__fio">
                                        <AsyncHighlighter>{contactInfo.fio}</AsyncHighlighter>
                                    </p>
                                )}
                                {phones?.map((phone, index) => {
                                    const { country, city, number, comment } = phone;
                                    const formattedPhone = `+${country}\u00a0(${city})\u00a0${number}`;
                                    return (
                                        <Fragment key={index}>
                                            <p
                                                data-qa="vacancy-contacts__phone"
                                                className="vacancy-contacts__phone-desktop"
                                            >
                                                {formattedPhone}
                                                {renderPhoneComment(comment)}
                                            </p>
                                            <div className="vacancy-contacts__phone-mobile">
                                                <BlokoLink
                                                    disableVisited
                                                    Element={Link}
                                                    to={`tel:+${country}${city}${number}`}
                                                    onClick={sendPhoneOnClickAnalytics}
                                                >
                                                    {formattedPhone}
                                                </BlokoLink>
                                                {renderPhoneComment(comment)}
                                            </div>
                                        </Fragment>
                                    );
                                })}
                                {contactInfo?.email && (
                                    <Link
                                        to={`mailto:${contactInfo.email}`}
                                        rel="nofollow"
                                        data-qa="vacancy-contacts__email"
                                        onClick={sendEmailOnClickAnalytics}
                                    >
                                        {contactInfo.email}
                                    </Link>
                                )}
                            </div>
                        ))}
                </VacancySectionWrapper>
            </div>
        </NoIndex>
    );
};

export default translation(VacancyContacts);
