import { type ButtonProps } from '@hh.ru/magritte-ui';

export enum TooltipType {
    Click = 'click',
    Hover = 'hover',
}

export type ActionPropsType = Omit<
    ButtonProps,
    'style' | 'mode' | 'size' | 'Element' | 'aria-label' | 'hideLabel' | 'icon' | 'subcaption'
>;
