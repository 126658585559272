import { FC, PropsWithChildren } from 'react';

import { Title, useBreakpoint } from '@hh.ru/magritte-ui';
import { H2 } from 'bloko/blocks/header';

import useMagritte from 'src/hooks/useMagritte';

const Heading: FC<PropsWithChildren> = ({ children }) => {
    const isMagritte = useMagritte();
    const { isMobile } = useBreakpoint();

    if (isMagritte) {
        return (
            <Title Element="h2" size={isMobile ? 'small' : 'medium'}>
                {children}
            </Title>
        );
    }

    return <H2>{children}</H2>;
};

export default Heading;
