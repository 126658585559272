import React, { useRef, useState, useEffect } from 'react';

import {
    BottomSheet,
    BottomSheetFooter,
    Button,
    Link as MagritteLink,
    Text,
    NavigationBar,
    TooltipHover,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { FlyInFlyOutDuration, WorkScheduleByDays, WorkingHours, WorkFormat } from '@hh.ru/types-hh-microcore';
import Link from 'bloko/blocks/link';
import { TranslationHOCProps } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import { useCountingTexts } from 'src/components/VacancyView/VacancyEmployment/VacancyNewEmploymentsFields/Helpers/hooks';

import styles from './styles.less';

const TrlKeys = {
    and: 'vacancy.view.employment.counting.and',
    close: 'vacancy.view.employment.counting.close',
    bottomSheetTitle: {
        flyInFlyOutDuration: 'vacancy.view.employment.counting.bottomSheet.title.flyInFlyOutDuration',
        workScheduleByDays: 'vacancy.view.employment.counting.bottomSheet.title.workScheduleByDays',
        workingHours: 'vacancy.view.employment.counting.bottomSheet.title.workingHours',
        workFormats: '',
    },
};

interface EmploymentFieldsValueCountingProps<T> {
    field: 'flyInFlyOutDuration' | 'workScheduleByDays' | 'workingHours' | 'workFormats';
    values: T[];
    dictionary: {
        id: T;
        text: string;
    }[];
    isMagritteVacancy?: boolean;
}

const CountingLink: React.FC<{
    activatorRef: React.RefObject<HTMLAnchorElement>;
    isMagritteVacancy?: boolean;
    children: React.ReactNode;
    onClick: () => void;
}> = ({ activatorRef, isMagritteVacancy, children, onClick }) => {
    if (isMagritteVacancy) {
        return (
            <MagritteLink inline ref={activatorRef} onClick={onClick}>
                {children}
            </MagritteLink>
        );
    }
    return (
        <Link ref={activatorRef} onClick={onClick}>
            {children}
        </Link>
    );
};

const EmploymentFieldsValueCounting = function <
    T extends WorkingHours | FlyInFlyOutDuration | WorkScheduleByDays | WorkFormat,
>({
    field,
    dictionary,
    values,
    isMagritteVacancy,
    trls,
}: EmploymentFieldsValueCountingProps<T> & TranslationHOCProps): React.ReactElement | null {
    const { isMobile } = useBreakpoint();

    const [isBottomSheetVisible, setBottomSheetVisibility] = useState(false);
    const [isRendered, setIsRendered] = useState(false);

    const activatorRef = useRef<HTMLAnchorElement>(null);

    const { text, count, tooltip, bottomSheet } = useCountingTexts({ field, dictionary, values, trls });

    const toggleBottomSheetVisibility = () => {
        if (!isMobile) {
            return;
        }

        setBottomSheetVisibility(!isBottomSheetVisible);
    };

    useEffect(() => {
        setIsRendered(true);
    }, []);

    if (values.length === 0) {
        return null;
    }

    return (
        <>
            {text}

            {count && (
                <span className={styles.countingMore}>
                    {` ${trls[TrlKeys.and]} `}
                    {/* Сначала показываем текстовое представление, потом уже ссылку
                    Связано это с тем, что стили не успевают подсасываться и текст прыгает */}
                    {isRendered ? (
                        <CountingLink
                            activatorRef={activatorRef}
                            isMagritteVacancy={isMagritteVacancy}
                            onClick={toggleBottomSheetVisibility}
                        >
                            {count}

                            {!isMobile && (
                                <TooltipHover placement="bottom-center" activatorRef={activatorRef}>
                                    {tooltip}
                                </TooltipHover>
                            )}
                        </CountingLink>
                    ) : (
                        count
                    )}
                </span>
            )}

            {isMobile && (
                <BottomSheet
                    visible={isBottomSheetVisible}
                    onClose={toggleBottomSheetVisibility}
                    header={<NavigationBar title={trls[TrlKeys.bottomSheetTitle[field]]} />}
                    footer={
                        <BottomSheetFooter>
                            <Button mode="secondary" onClick={toggleBottomSheetVisibility}>
                                {trls[TrlKeys.close]}
                            </Button>
                        </BottomSheetFooter>
                    }
                >
                    <Text typography="label-1-regular">{bottomSheet}</Text>
                </BottomSheet>
            )}
        </>
    );
};

export default translation(EmploymentFieldsValueCounting);
