import { useRef, FC } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import Clickme from 'src/components/Clickme';
import { useSelector } from 'src/hooks/useSelector';

import { useTrackBannersClickEvent } from 'src/components/VacancyView/useBannersAnalytics';

const VacancyAdaptiveBanners: FC = () => {
    const banners = useSelector(({ banners }) => banners);
    const adsVacancyParams = useSelector((state) => state.adsVacancyParams);

    const rightColumnBanners = banners['right-column'];
    const rightColumnClickmeAdaptiveBanners = banners['right-column-clickme-adaptive'];

    const containerRef = useRef(null);
    useTrackBannersClickEvent({ containerRef });
    if (!rightColumnBanners?.length && !rightColumnClickmeAdaptiveBanners?.length) {
        return null;
    }
    return (
        <div className="noprint" ref={containerRef}>
            <VSpacing base={6} />
            <div className="vacancy-adaptive-banners">
                {rightColumnBanners && rightColumnBanners.length > 0 && (
                    <Column xs="4" s="4" m="6" l="0">
                        {rightColumnBanners.map((banner) => (
                            <AdsExternalBanner
                                {...banner}
                                cId={`adaptive_${banner.cId}`}
                                key={banner.id}
                                bannerAdTargeting={adsVacancyParams}
                            />
                        ))}
                    </Column>
                )}
                {rightColumnClickmeAdaptiveBanners && rightColumnClickmeAdaptiveBanners.length > 0 && (
                    <Column xs="4" s="4" m="6" l="0">
                        <Clickme banners={rightColumnClickmeAdaptiveBanners} />
                    </Column>
                )}
            </div>
        </div>
    );
};

export default VacancyAdaptiveBanners;
