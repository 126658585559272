import { FC, RefObject } from 'react';

import { Card } from '@hh.ru/magritte-ui';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import VacancyCompanyRedesigned from 'src/components/VacancyView/VacancyCompanyRedesigned';
import VacancyVideo from 'src/components/VacancyView/VacancyVideo';
import { useSelector } from 'src/hooks/useSelector';

import styles from './vacancy-block.less';

type Props = {
    vacancyBodyFooterNodeRef?: RefObject<HTMLDivElement>;
};

const VacancyBlockEmployer: FC<Props> = ({ vacancyBodyFooterNodeRef }) => {
    const isMagritteVacancy = useSelector((state) => state.isMagritteVacancy);
    const employerContent = isMagritteVacancy ? (
        <Card stretched padding={24} borderRadius={24} showBorder>
            <VacancyCompanyRedesigned isMagritteVacancy />
        </Card>
    ) : (
        <div className={styles.blockEmployer}>
            <div className={styles.wrapper}>
                <VacancyCompanyRedesigned />
            </div>
        </div>
    );

    return (
        <ColumnsRow>
            <Column xs="4" s="8" m="12" l="6">
                {employerContent}
                <VacancyVideo vacancyBodyFooterNodeRef={vacancyBodyFooterNodeRef} />
            </Column>
            <VSpacing base={14} xs={9} />
        </ColumnsRow>
    );
};

export default VacancyBlockEmployer;
