import { useEffect } from 'react';

import useOnOffState from 'src/hooks/useOnOffState';

export default (): boolean => {
    const [isPrinting, handleBeforePrint, handleAfterPrint] = useOnOffState(false);

    useEffect(() => {
        window.addEventListener('beforeprint', handleBeforePrint);
        window.addEventListener('afterprint', handleAfterPrint);
        return () => {
            window.removeEventListener('beforeprint', handleBeforePrint);
            window.removeEventListener('afterprint', handleAfterPrint);
        };
    }, [handleAfterPrint, handleBeforePrint]);

    return isPrinting;
};
