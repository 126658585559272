import { Text, Title, VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { AppStore } from 'src/app/store';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import VacancySectionWrapper from 'src/components/VacancyView/VacancySectionWrapper';

export const checkVacancyDriverLicenseRender = ({ vacancyView }: AppStore): boolean =>
    !!vacancyView.driverLicenseTypes?.driverLicenseType?.length;

const TrlKeys = {
    driverExperience: 'vacancy.view.driverExperience',
    driverLicense: 'vacancy.view.driverLicense',
};

interface VacancyDriverLicenseProps {
    hasVacancySectionWrapper?: boolean;
    isEmployerView?: boolean;
}

const VacancyDriverLicense: TranslatedComponent<VacancyDriverLicenseProps> = ({
    hasVacancySectionWrapper = true,
    isEmployerView = false,
    trls,
}) => {
    const driverLicenseTypes = useSelector(({ vacancyView }) => vacancyView.driverLicenseTypes);
    const showDriverLicense = useSelector(checkVacancyDriverLicenseRender);
    const isMagritteVacancy = useSelector((state) => state.isMagritteVacancy);

    if (!driverLicenseTypes?.driverLicenseType || !showDriverLicense) {
        return null;
    }

    return (
        <VacancySectionWrapper hasWrapper={hasVacancySectionWrapper}>
            <Title Element="h2" size="extra-small">
                {trls[TrlKeys.driverExperience]}
            </Title>
            {isMagritteVacancy ? <MagritteVSpacing default={16} /> : <VSpacing base={4} />}
            <Text
                Element="span"
                typography={isEmployerView || isMagritteVacancy ? 'label-2-regular' : 'label-3-regular'}
            >
                {trls[TrlKeys.driverLicense]} {driverLicenseTypes.driverLicenseType.join(', ')}
            </Text>
        </VacancySectionWrapper>
    );
};

export default translation(VacancyDriverLicense);
